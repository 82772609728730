import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Form, Upload, Switch, Input, Breadcrumb, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import * as imageService from '../../../../Post/Image/services';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionPostsGet,
  actionSpaceLogoEdit,
  actionSpaceNameEdit,
} from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, POSTS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
const { TextArea } = Input;

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionPostsGet,
  actionSpaceNameEdit,
  actionSpaceLogoEdit,
};

const SpaceSettings = (props) => {
  const params = useParams();

  let accounts = [];
  useEffect(() => {
    const service = new AkukoAPIService(IDENTITY_API, 'space');
    service
      .read(params.id)
      .then((space) => {
        props.actionAccountSpaceGet(space);

        const service = new AkukoAPIService(POSTS_API, `post/space/${params.id}`);
        return service.list();
      })
      .then((posts) => {
        props.actionPostsGet(posts);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB.');
    }
    return isJpgOrPng && isLt2M;
  };

  if (props.space.id) {
    return (
      <div className="dashboard">
        <div className="spaces">
          <div className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>{props.space.account_id}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>spaces</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/space/${props.space.id}`}>{props.space.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>settings</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1>Settings</h1>
          <Row gutter={40}>
            <Col xs={12}>
              <Form
                layout="vertical"
                onFinish={(values) => {
                  props.actionSpaceNameEdit({
                    value: values.space_name,
                  });
                  setTimeout(() => {
                    const space = Object.assign({}, props.space);
                    space.name = values.space_name;
                    const config = Object.assign({}, space.config);
                    config.description = values.space_description;
                    config.isPublic = values.isPublic;
                    config.website = values.website;
                    config.showContributors = values.showContributors;
                    space.config = config;
                    const service = new AkukoAPIService(IDENTITY_API, `space/${space.uuid}`);
                    service
                      .update(space)
                      .then(() => {
                        message.success('Space settings updated');
                      })
                      .catch((error) => {
                        message.error(error.message || ERROR_GENERIC);
                      });
                  }, 500);
                }}
              >
                <Form.Item
                  name="space_name"
                  label="Space name"
                  initialValue={props.space.name}
                  rules={[{ required: true }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item name="space_url" label="Space URL" initialValue={props.space.id}>
                  <Input
                    disabled
                    size="large"
                    addonBefore="https://"
                    addonAfter=".akuko.io"
                    placeholder="my-space"
                  />
                </Form.Item>
                <Form.Item
                  initialValue={props.space.config.description}
                  name="space_description"
                  label="Space description"
                >
                  <TextArea rows={8} />
                </Form.Item>
                <Form.Item initialValue={props.space.config.website} name="website" label="Website">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="isPublic"
                  valuePropName="checked"
                  initialValue={props.space.config.isPublic}
                  label="Public Space"
                  help={
                    'This will allow anyone to view your space index page. You will still need to make your Posts public.'
                  }
                >
                  <Switch size="large" />
                </Form.Item>
                <Form.Item
                  name="showContributors"
                  valuePropName="checked"
                  initialValue={props.space.config.showContributors}
                  label="Show contributors"
                  help={'Shows a contributors block on your space index.'}
                >
                  <Switch size="large" />
                </Form.Item>
                {/*}
                <Form.Item
                  name="space_archive"
                  label="Archive Space"
                  help={
                    'This will deactivate the space and hide it from your dashboard. Posts in this space will no longer be accessible.'
                  }
                >
                  <Switch disabled size="large" />
                </Form.Item>
                {*/}
                <br />
                <br />
                <Button type="primary" size="large" htmlType="submit">
                  Submit
                </Button>
              </Form>
            </Col>
            <Col sm={8}>
              <label>Space logo</label>

              <Upload
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(e) => {
                  if (e.file) {
                    imageService
                      .imageUploadService(e.file.originFileObj)
                      .then((res) => {
                        props.actionSpaceLogoEdit({
                          value: res,
                        });
                        setTimeout(() => {
                          const space = Object.assign({}, props.space);
                          const config = Object.assign({}, space.config);
                          config.logo_file = res;
                          space.config = config;
                          const service = new AkukoAPIService(IDENTITY_API, `space/${space.uuid}`);
                          service.update(space).catch((error) => {
                            message.error(error.message || ERROR_GENERIC);
                          });
                        }, 500);
                      })
                      .catch((error) => {
                        message.error(error.message || ERROR_GENERIC);
                      });
                  }
                }}
              >
                <div className="space-logo-uploader">
                  {props.space.config.logo_file && (
                    <>
                      <img
                        style={{ height: '40px' }}
                        src={`https://assets.akuko.io/${props.space.config.logo_file}`}
                      />
                    </>
                  )}
                  {!props.space?.config?.logo_file && <div style={{ marginTop: 8 }}>Upload</div>}
                </div>
              </Upload>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const SpaceSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(SpaceSettings);

export default withRouter(SpaceSettingsContainer);

{
  /*}
rules={[
  {
    required: true,
    message: 'Space URL is not available',
    validator: () => {
      return Promise.reject();
    },
  },
]}
{*/
}
