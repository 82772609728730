import React from 'react';
import { Row, Col, Input, Popover, Button } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { MemoizedSymbolPicker, Icon } from '../SymbolPicker';
import './SymbolInput.css';
import { TooltipPlacement } from 'antd/lib/tooltip';

/** interface for component props */
export interface SymbolInputProps {
  symbol?: Icon;
  value?: string;
  color?: string;
  placement?: TooltipPlacement;
  handleValueEdit?: (value: string) => void; // handler for value input change
  handleSymbolEdit: (icon: Icon) => void; // handler for symbol input
  handleColorEdit?: (color: string) => void; // handle color change
  handleRemoval?: () => void; // handler to handle input removal
  addonBefore?: string; // text displayed before (on the left side of) the input field
  enableDelete?: boolean; // boolean to enable/disable delete icon
  showValueInput?: boolean; // boolean to hide/display value input field
}

/** default component props */
const defaultProps: Partial<SymbolInputProps> = {
  addonBefore: '=',
  enableDelete: true,
  showValueInput: true,
};

const SymbolInput: React.FC<SymbolInputProps> = (props: SymbolInputProps) => {
  const {
    value,
    symbol,
    addonBefore,
    placement,
    enableDelete,
    showValueInput,
    handleValueEdit,
    handleRemoval,
  } = props;

  return (
    <div>
      {enableDelete && handleRemoval && (
        <DeleteOutlined
          className="symbol-input--delete"
          onClick={() => {
            handleRemoval();
          }}
        />
      )}

      {showValueInput && handleValueEdit && (
        <>
          <label htmlFor="symbol-value-input">Value</label>
          <Input
            id="symbol-value-input"
            addonBefore={addonBefore}
            placeholder="Value"
            defaultValue={value}
            onBlur={(e) => {
              handleValueEdit(e.target.value);
            }}
          />
        </>
      )}

      <Row>
        <Col sm={8}>
          <Popover
            placement={'left'}
            content={
              <MemoizedSymbolPicker onChangeComplete={(icon) => props.handleSymbolEdit(icon)} />
            }
          >
            <Button size="large">
              {symbol?.id ? (
                <img
                  style={{ height: '40px', width: '40px' }}
                  className="symbol-input--preview"
                  src={symbol.src}
                  alt={symbol.title}
                />
              ) : (
                <SearchOutlined />
              )}
            </Button>
          </Popover>
        </Col>
      </Row>
    </div>
  );
};

SymbolInput.defaultProps = defaultProps;

export { SymbolInput };

/**
 * Memoized component is helpful to ensure every instance of SymbolInput does not re-render
 * if the props of one input change
 */
export const MemoizedSymbolInput = React.memo(SymbolInput);
