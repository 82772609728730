import React, { useState } from 'react';
import { Button, Dropdown, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { useLocation, useParams } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useHistory } from 'react-router-dom';
import { actionUserLogout } from '../actions';
import { ExportPost } from '../../Post/PostComponents/components/ExportPost';
import { AkukoAPIService } from '../../../services/serviceClass';
import { POSTS_API, SOURCES_API, IDENTITY_API } from '../../../configs/env';
import { EmbedSettings } from '../../Post/Embed/EmbedSettings';
import { userHasWriteAccess } from '../../Profile/components/ProfileMenu/helpers';
import { ERROR_GENERIC } from '../../../configs/constants';
import mixpanel from 'mixpanel-browser';
import './style.css';

const UserDropdown = (): JSX.Element => {
  const user = useSelector((store: Dictionary) => store.user);
  const post = useSelector((store: Dictionary) => store.post);
  const space = useSelector((store: Dictionary) => store.space);
  const history = useHistory<Dictionary>();
  const location = useLocation<Dictionary>();
  const params = useParams<Dictionary>();
  const dispatch = useDispatch();
  const [exportPost, setExportPost] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const pathname: string = location.pathname;

  const defaultItems: MenuProps['items'] = [
    {
      label: (
        <a
          href="#dashboard"
          onClick={(e) => {
            e.preventDefault();
              history.push(`/dashboard/settings`);
            
          }}
        >
          My Account
        </a>
      ),
      key: 'dashboard',
    },
    { type: 'divider' },
    {
      label: (
        <a
          href="#sign-out"
          onClick={(e) => {
            e.preventDefault();
            const service = new AkukoAPIService(IDENTITY_API, 'user/auth');
            service.delete().then(() => {
              dispatch(actionUserLogout());
            });
          }}
        >
          Sign Out
        </a>
      ),
      key: 'sign-out',
    },
  ];

  if (user && user.uuid) {
    return (
      <>
        <Dropdown menu={{ items: defaultItems }} overlayClassName={'user-dropdown'}>
          <span className="user-icon">
            <span className="user-icon-name">{user.handle.charAt(0).toUpperCase()}</span>
          </span>
        </Dropdown>
        <EmbedSettings
          onCancel={() => {
            setModalVisible(false);
          }}
          showModal={modalVisible}
        />
      </>
    );
  } else {
    return (
      <>
        <Button
          className="btn-user-sign-in"
          onClick={() => {
            history.push(`/user/login`);
          }}
        >
          Sign In
        </Button>
        <Button
          type="primary"
          className="btn-user-sign-up"
          onClick={() => {
            history.push(`/user/sign-up`);
          }}
        >
          Sign Up
        </Button>
      </>
    );
  }
};

export { UserDropdown };
