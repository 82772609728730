import React, { useEffect, useState } from 'react';
import { Input, Modal, Form, Select, Checkbox, Button, message } from 'antd';
import { IDENTITY_API, ADMIN_API } from '../../../configs/env';
import { AkukoAPIService } from '../../../services/serviceClass';
import { useParams, useHistory } from 'react-router-dom';
import { Dictionary } from '@onaio/utils/dist/types/types';
import Footer from '../../Post/Footer';
import Loader from '../../Post/Loader/Loader';
import mixpanel from 'mixpanel-browser';

const { Option } = Select;
import './style.css';

const Register: React.FC = () => {

 const params:Dictionary = useParams();
 const history:Dictionary = useHistory();
 const [invite, setInvite] = useState<Dictionary>();
 const [loading, setLoading] = useState(false);


  if (!loading) {
 return(
  <div className="form-register">
   <img className="form-register--brand" src="https://assets.akuko.io/akuko-color-logo.png" />
   <h1>Create your account</h1>
   <p>We&apos;re excited for you to try Akuko.</p>
      <Form layout="vertical"
        initialValues={{
          email: invite?.email
        }}
        onFinish={(values) => {
          if (values.password !== values['confirm password']) {
            return message.error('Passwords do not match.')
          }
          if (values.password.length < 8) {
            return message.error('Password must be a minimum of 8 characters.')
          }
          const service = new AkukoAPIService(IDENTITY_API, 'user/add');
           service
            .create({ ...values, invite: params.id, firstName: invite?.first_name, lastName: invite?.last_name })
            .then(() => {
              message.success('Account created. Please sign in.');
              mixpanel.identify(values.email);
              mixpanel.track('Sign up', {});
              history.push(`/user/login`)
            })
            .catch(() => {
              message.error('Error creating account or account already exists.');
            });
        }}
      >
        <Form.Item
          name="email"
          label="email"
          rules={[{
            required: true,
            type: 'email'
          }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label="password"
          rules={[{
            required: true
          }]}
        >
          <Input.Password size="large"/>
        </Form.Item>
        <Form.Item
          name="confirm password"
          label="Confirm password"
          rules={[{
            required: true
          }]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
              name="terms and conditions"
              valuePropName="checked"
              initialValue={false}
              rules={[
                {
                  required: true,
                  transform: (value) => value || undefined,
                  type: 'boolean',
                },
              ]}
            >
              <Checkbox className="form-sign-up--terms">
                I agree to the {' '}
                  <a 
                    target="blank"
                    href="https://akuko.io/terms"
                  >
                  Terms of Service
                  </a>.
              </Checkbox>
            </Form.Item>

       <Button type="primary" className="form-register--submit" size="large" htmlType="submit">Sign up</Button>
       {/*}
       <p className="or">or</p>
       <Button size="large" type="primary">Sign in with Google</Button>
        {*/}

       </Form>
       <Footer />
  </div>
 )
  } else {
    return (<Loader />)
  }
};

export { Register };
