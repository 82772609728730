import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Switch, message } from 'antd';
import { connect } from 'react-redux';
import Loader from '../../../../Post/Loader/Loader';
import { useParams, useHistory } from 'react-router-dom';
import { actionAccountSpaceAdd, actionAccountSpaceGet } from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
};

export const SpaceForm = (props) => {
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(params.id);
  const [form] = Form.useForm();

  const makeSpaceId = (name) => {
    const spaceId = name.replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'"<>,.? ])+/g, '-');
    return spaceId.toLowerCase();
  };

  useEffect(() => {
    setAccountId(params.id);
  }, [params.id]);

  return (
    <Modal
      visible={props.showModal}
      footer={null}
      onCancel={() => {
        props.setShowModal(false);
      }}
    >
      <Form
        form={form}
        layout={'vertical'}
        onValuesChange={(values) => {
          if (values.name) {
            form.setFieldsValue({ id: makeSpaceId(values.name) });
          }
          if (values.id) {
            form.setFieldsValue({ id: makeSpaceId(values.id) });
          }
        }}
        onFinish={(values) => {
          values.account_id = accountId;
          setLoading(true);
          const spaces = Object.assign([], props.user.spaces);
          spaces.push({ id: values.id, role: 'admin' });
          const user = Object.assign({}, props.user);
          user.spaces = spaces;
          const service = new AkukoAPIService(IDENTITY_API, 'space');
          service
            .create(values)
            .then((res) => {
              props.actionAccountSpaceAdd(res);
              const service = new AkukoAPIService(IDENTITY_API, `user/${user.uuid}`);
              return service.update({ ...user, current_space_id: values.id });
            })
            .then(() => {
              props.setShowModal(false);
              setLoading(false);
              form.resetFields();
              message.success('Space created');
              history.push(`/space/${values.id}/posts`);
            })
            .catch((err) => {
              setLoading(false);
              message.error(err.message || ERROR_GENERIC);
            });
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="My Space" />
        </Form.Item>
        <Form.Item
          name="id"
          label="URL"
          hasFeedback
          validateTrigger="onChange"
          rules={[
            {
              required: true,
              message: 'Space URL is not available',
              validator: (field, value) => {
                if (value.length > 2) {
                  const service = new AkukoAPIService(IDENTITY_API, 'space');
                  return service.read(value).then((data) => {
                    if (data.msg) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  });
                } else {
                  return Promise.reject();
                }
              },
            },
          ]}
        >
          <Input
            size="large"
            addonBefore="https://"
            addonAfter=".akuko.io"
            placeholder="my-space"
          />
        </Form.Item>
        <Button disabled={loading} type="primary" size="large" htmlType="submit">
          Create space
        </Button>
      </Form>
    </Modal>
  );
};

const SpaceFormContainer = connect(mapStateToProps, mapDispatchToProps)(SpaceForm);

export default SpaceFormContainer;
