import React from 'react';
import { Input, Form, Select, Button, Row, Col, message } from 'antd';
import { Link } from 'react-router-dom';
import Footer from '../../Post/Footer';
import { AkukoAPIService } from '../../../services/serviceClass';
import { IDENTITY_API } from '../../../configs/env';
import { Dictionary } from '@onaio/utils/dist/types/types';

const { Option } = Select;
import './style.css';

const SignUp: React.FC = () => {
  const [form] = Form.useForm();

  return (
    <div className="form-sign-up">
      <img className="form-sign-up--brand" src="https://assets.akuko.io/akuko-color-logo.png" />
      <h1>Sign up for our public beta</h1>
      <p>
        Thanks for your interest in Akuko! We are currently accepting a limitted number of beta
        users on a rolling basis.
      </p>
      <p className="form-sign-up--login">
        Already have an account? <Link to="/user/login">Sign in</Link>
      </p>
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          const checkIfUserExists = new AkukoAPIService(IDENTITY_API, 'user');
          await checkIfUserExists.read(values.email).then(async (res) => {
            const data = res as Dictionary;
            if (data.uuid) {
              message.error('User already exists.');
            } else {
              const service = new AkukoAPIService(IDENTITY_API, 'user/invite');
              const email = values?.email?.toLowerCase();
              values.email = email;
              await service
                .create(values)
                .then(() => {
                  message.success('Your request has been submitted.');
                  form.resetFields();
                })
                .catch((error) => {
                  message.error(error.message || 'Error submitting request.');
                });
            }
          });

          /*
          const service = new AkukoAPIService(IDENTITY_API, 'user/invite');
          await service
            .create(values)
            .then(() => {
              message.success('Your request has been submitted.');
              form.resetFields();
            })
            .catch(() => {
              message.error('Error submitting request.');
            });
            */
        }}
      >
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="first_name"
              label="First name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="First name" size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="last_name"
              label="Last name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Last name" size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Your email" size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="organization"
              label="Organization"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Your Organization" size="large" />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              name="role"
              label="Primary role"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Please select" size="large">
                <Option value="Developer">Developer</Option>
                <Option value="Data visualization specialist">Data visualization specialist</Option>
                <Option value="Data Analyst">Data Analyst</Option>
                <Option value="GIS professional">GIS professional</Option>
                <Option value="Project manager">Project manager</Option>
                <Option value="Designer">Designer</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              name="source"
              label="How did you hear about Akuko?"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Please select" size="large">
                <Option value="LinkedIn">LinkedIn</Option>
                <Option value="Facebook">Facebook</Option>
                <Option value="Twitter">Twitter</Option>
                <Option value="Team member">Akuko team member</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24}>
            <p className="form-sign-up--legal">
              By filling this registration form, you express your interest in joining the Akuko
              public beta program. If your request is approved, you will receive an invite email to
              create an Akuko account. Thank you for helping create something new and amazing.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }} gutter={20}>
          <Col sm={24}>
            <Button className="form-sign-up--submit" type="primary" size="large" htmlType="submit">
              Request Access
            </Button>
          </Col>
        </Row>
      </Form>
      <Footer />
    </div>
  );
};

export { SignUp };
