import { Dictionary } from '@onaio/utils/dist/types/types';
import { ReactNode } from 'react';

export interface OptionsProps {
  value: string | number | boolean;
  label: string | ReactNode;
}

export interface ColorValueProps {
  color: string;
  value: number | string;
}

export interface LegendValueProps {
  color: string;
  value: number;
}

export interface SectionProps {
  [key: string]: string[];
}
export interface DependsOn {
  parent?: string;
  property: string | (string | number)[];
  value:
    | undefined
    | string
    | number
    | string[]
    | boolean
    | boolean[]
    | (string | boolean)[];
  nestedDataTypeKey?: string;
  dataTypeKey?: string;
}

export type LabelDependsOn = DependsOn & OptionsProps;

export interface FieldProps {
  type: string; // input field type
  label?: string; // input field label
  property?: string; // redux property to update
  parents?: string[]; // for nested redux properties, names of parents
  showMeasures?: boolean; // for dimension input
  showDimensions?: boolean; // for dimension input
  cols?: number; // number of columns for grid layout
  group?: string; // group to which input should belong
  section?: string; // child of group, sub section
  item?: string; // the field object
  itemLabel?: string; // for arrayInputs, the array item label
  min?: number; // for number / slider inputs, min number allowed
  max?: number; // for number / slider inputs, max number allowed
  step?: number; // for number / slider, amount to increment
  refreshKey?: boolean; // for map, reset component key (force rerender)
  isMapLayer?: boolean; // for map, property is on map layer
  getMinMax?: boolean; // fetch min / max values in data
  hasGeometries?: boolean; // for map, layer has geometries
  addonBefore?: string; // for text input (prefix text)
  setType?: boolean; // for dimension (set dimension / measure type)
  setNestedType?: boolean; // for dimension (set dimension / measure type) if property is nested
  setDataType?: boolean; // for dimension (set data type string, time, int etc)
  setNestedDataType?: boolean; // for dimension (set data type string, time, int etc) if property is nested
  typeKey?: string; // for dimension (set dimension / measure type) nested key for type
  dataTypeKey?: string; // for dimension type (set data type string, time, int etc) nested key for type
  nestedDataTypeKey?: string; // for dimension (set data type string, time, int etc) nested key for type
  arrayIndex?: number; // if property is array, array index to update
  objectKey?: string; // if property is object, key to update
  dependsOn?: DependsOn[]; // conditions for input display
  defaultValue?: string | Dictionary; // default value for input
  defaultValueType?: string; // type of default value
  options?: OptionsProps[]; // for select input, options to render
  groups?: string[]; // setting group for input field
  fields?: FieldProps[]; // for array input, fields for array item
  events?: Dictionary;
  entity?: string; // set 'post' to update root post properties, e.g. post->title
  collapseParent?: string; // parent for nested properties e.g symbolCategories
  collapseProperty?: string; // overrides collapse label to equal the 1st select value
  expectedType?: string; // expected type for input
  expectedDataType?: string; // expected data type for input
  selectMode?: string; // select mode for select input
  placeHolder?: string; // inputs placeholder
  addButtonText?: string;
  tooltip?: string;
  settings?: Dictionary[];
  advanced?: boolean;
  labelLink?: string;
  componentType?: string;
  hideClassInput?: boolean;
  allowGranularity?: boolean;
  setDataSource?: boolean;
  dataSourceKey?: string;
  hasContext?: boolean;
  disabled?: boolean;
  disableOpacity?: boolean;
  excludeLayer?: boolean;
  popOver?: boolean;
  size?: string;
}

export interface MapFieldProps {
  type?: string;
  label?: string;
  property?: string;
  group?: string;
  section?: string;
  item?: string;
  itemLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  options?: OptionsProps[];
  groups?: string[];
  nested?: boolean;
  fields?: FieldProps[];
  dependsOn?: DependsOn[];
}

export interface SymbolValueProps {
  symbol: {
    src: string;
    title: string;
    color: string;
    id: 'string';
  };
  value: number;
}

export interface LayerProps {
  id: string;
  type: string;
  layerType?: string;
  label: string;
  property?: string;
  group?: string;
  section?: string;
  item?: string;
  itemLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  fields?: FieldProps[];
  tooltip?: string;
  joinField?: string;
  targetField?: string;
  source?: string;
  geometry?: Dictionary;
  geometryIndex?: number;
  geometries?: Dictionary[];
  symbolPrefix?: string;
  symbolCategories?: SymbolValueProps[];
  symbolSuffix?: string;
  symbolField?: string;
  symbolSize?: string;
  symbolOffsetX?: string;
  symbolOffsetY?: string;
  circleRadius?: number;
  minMaxZoom?: number[];
  hasMenuItem?: boolean;
  minZoom?: number;
  maxZoom?: number;
  children?: Dictionary[];
  hasToggle?: boolean;
  visible?: boolean;
  layerName?: string;
  layerGroupName?: string;
  legend?: Dictionary;
  colorMethod?: string;
  colorMode?: string;
  fillFieldMinValue?: number;
  fillFieldMaxValue?: number;
  fillFieldStartColor?: string;
  fillFieldEndColor?: string;
  colorSteps?: ColorValueProps[];
  colorCategories?: ColorValueProps[];
  colorBreaks?: ColorValueProps[];
  size?: string;
}

export interface GroupFields {
  type: string;
  label: string;
  property?: string;
  propertyKey?: string;
  propertyType?: string;
  parentProperty?: string;
  showMeasures?: boolean;
  showDimensions?: boolean;
  group?: string;
  section?: string;
  settings?: Dictionary[];
  item?: string;
  itemLabel?: string;
  objectKey?: string;
  setType?: boolean;
  typeKey?: string;
  dataTypeKey?: string;
  nestedDataTypeKey?: string;
  setNestedType?: boolean;
  setNestedDataType?: boolean;
  setDataType?: boolean;
  collapseProperty?: string;
  collapseParent?: string;
  disabled?: boolean;
  addButtonText?: string;
  min?: number;
  max?: number;
  step?: number;
  cols?: number;
  parents?: string[];
  arrayIndex?: number;
  defaultValueType?: string;
  defaultValue?: string | Dictionary | number;
  collapse?: boolean;
  options?: OptionsProps[];
  groups?: string[];
  nested?: boolean;
  fields?: FieldProps[];
  dependsOn?: DependsOn[];
  entity?: string;
  advanced?: boolean;
  isMapLayer?: boolean;
  inherit?:string;
  hasGeometries?: boolean;
  getMinMax?: boolean;
  refreshKey?: boolean;
  expectedType?: string;
  expectedDataType?: string;
  componentType?: string;
  template?: string;
  selectMode?: string;
  placeHolder?: string;
  tooltip?: string;
  labelLink?: string;
  hideClassInput?: boolean;
  limitSortOptions?: boolean;
  allowGranularity?: boolean;
  setDataSource?: boolean;
  dataSourceKey?: string;
  hasContext?: boolean;
  disableOpacity?: boolean;
  excludeLayer?: boolean;
  popOver?: boolean;
  size?: string;
}

export interface GroupFieldProps {
  groups: string[];
  map?: string[];
  styles?: string[];
  sections?: SectionProps;
  fields: GroupFields[];
  events?: boolean;
  filters?: boolean;
  settings?: Dictionary[];
}

// component settings types

export const ChartGroupSettings = {
  SETTINGS: 'Settings',
  SOURCE: 'Source',
  LAYOUT: 'Style',
  FILTERS: 'Filters',
  CHART: 'Chart',
  SORT: 'Sort',
};

export type ChartGroupSettingsTypes =
  | 'Settings'
  | 'Source'
  | 'Style'
  | 'Filters'
  | 'Chart'
  | 'Sort';
export type ChartPositionTypes = 'top' | 'right' | 'bottom' | 'left';

export interface ChartField {
  type: string;
  label: string;
  property: string;
  propertyType?: string;
  showMeasures?: boolean;
  showDimensions?: boolean;
  objectKey?: string;
  group: ChartGroupSettingsTypes;
  positionType?: ChartPositionTypes;
  arrayIndex?: number;
  min?: number;
  max?: number;
  options?: OptionsProps[];
}
export interface ChartFields {
  fields?: ChartFields[];
}

export interface ChartProps {
  groups: ChartGroupSettingsTypes[];
  fields: ChartFields & ChartField[];
}

export enum AKUKO_SOURCE_TYPES {
  GEOPARQUET = 'geoparquet',
  GEOJSON = 'geojson',
  PARQUET = 'parquet',
  CSV = 'csv',
  POSTGRESQL = 'postgres',
  MYSQL = 'mysql',
  CLICKHOUSE = 'clickhouse',
}
