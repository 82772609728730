import React, { useEffect, useState } from 'react';
import { InputNumber, Row, Col, Tooltip, Typography, Popover } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { ChartGroupSettingsTypes } from '../../../../../configs/types';
import { QuestionCircleFilled } from '@ant-design/icons';
import { handlePayloadDispatch } from './helpers';


export interface NumberInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item?: any
}

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const {
    property,
    label,
    defaultValue,
    tooltip,
    min,
    max,
    propertyKey,
    placeHolder,
    inherit,
    cols,
    objectKey,
    parents,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const space = useSelector((state: Dictionary) => state.space);

  const component = post.components[componentIndex];
  const { type } = component;
  const parentProperty = type === 'map' ? 'layers' : 'properties';
  const [value, setValue] = useState<number | undefined>(0);
  const [defaultPageInputSizeError, setDefaultPageSizeInputError] = useState('');
  const [inherited, setInherited] = useState(false);

  useEffect(() => {
    if (
      itemIndex !== undefined &&
      childIndex !== undefined &&
      component[parentProperty]?.[childIndex]?.['children']
    ) {
      if (component[parentProperty]?.[childIndex]?.['children']?.[itemIndex]) {
        setValue(component[parentProperty][childIndex]['children'][itemIndex][property]);
      }
    } else if (itemIndex !== undefined) {
      if (component[parentProperty]?.[itemIndex]) {
        setValue(component[parentProperty][itemIndex]?.[property]);
      }
      if (parents?.length && objectKey) {
        setValue(component[parents[0]]?.[itemIndex]?.[property]?.[objectKey]);
      }
    } else {
      if (propertyKey && component[property] && component[property][propertyKey] !== 'undefined') {
        setValue(component[property][propertyKey]);
      } else {
        setValue(component[property]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, itemIndex]);

  useEffect(() => {
    if (post?.config?.[inherit] || space?.config?.[inherit]) {
      setInherited(true)
    } else {
      setInherited(false)
    }
  }, [post?.config, space?.config])

  return (
    <Row gutter={10}>
      <Col sm={6}>
      { inherited && 
      <Popover
       overlayClassName='inherit-info'
       content={`[post.config]: ${post.config[inherit]}`}
      >
      <span className="setting-inherited"></span> 
      </Popover>
      }
      <label>
        {label}
        &nbsp;
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionCircleFilled />
          </Tooltip>
        )}
      </label>
      </Col>
      <Col sm={18}>
      <InputNumber
        min={min}
        max={max}
        style={cols === 24 ? { width: 250 } : undefined}
        placeholder={placeHolder ? placeHolder : undefined}
        value={value || defaultValue}
        onChange={(value) => {
            /* @ts-ignore */
            handlePayloadDispatch(dispatch, {
              parentProperty: parentProperty,
              property: property,
              componentIndex: componentIndex,
              itemIndex: itemIndex,
              childIndex: childIndex,
              value: value,
              setDefaultPageSizeInputError: setDefaultPageSizeInputError,
              propertyKey: propertyKey,
              objectKey,
              parents,
            });
          if (value) {
            setValue(value);
          } else {
            setValue(undefined);
          }
        }}
      />
      {defaultPageInputSizeError && (
        <Row>
          <Typography.Text type="danger">{defaultPageInputSizeError}</Typography.Text>
        </Row>
      )}
      </Col>
    </Row>
  );
};

export { NumberInput };
