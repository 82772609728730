/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input, Form, Button, message } from 'antd';
import { IDENTITY_API } from '../../../configs/env';
import { AkukoAPIService } from '../../../services/serviceClass';
import { useParams, useHistory } from 'react-router-dom';
import { Dictionary } from '@onaio/utils/dist/types/types';
import Loader from '../../Post/Loader/Loader';
import './style.css';

const AcceptInvite: React.FC = () => {
  const params: Dictionary = useParams();
  const history: Dictionary = useHistory();
  const [invite, setInvite] = useState<Dictionary>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // validate invite id
    const service = new AkukoAPIService(IDENTITY_API, 'user/byId');
    service
      .read(params.id)
      .then((data) => {
        const res = data as Dictionary;
        setInvite(res);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        message.error('Invalid invite code!');
        history.push('/user/register');
      });
  }, [params]);

  if (!loading) {
    return (
      <div className="form-register">
        <img className="form-register--brand" src="https://assets.akuko.io/akuko-color-logo.png" />
        <h1>Access the {invite?.spaces[0].id} space</h1>
        <p>As this is your first time here, you need to create a password.</p>
        <Form
          layout="vertical"
          initialValues={{
            email: invite?.email,
          }}
          onFinish={async (values) => {
            if (values.password !== values['confirm password']) {
              return message.error('Passwords do not match.');
            }
            if (values.password.length < 8) {
              return message.error('Password must be a minimum of 8 characters.');
            }
            const service = new AkukoAPIService(IDENTITY_API, 'user/change-password');
            await service
              .create({
                email: invite?.email,
                newPassword: values.password,
              })
              .then(() => {
                message.success('Password updated. Please sign in.');
                history.push({
                  pathname: `/user/login/`,
                  search: `?redirect=/space/${invite?.spaces[0].id}`,
                });
              })
              .catch(() => {
                message.error('Authentication Error');
              });
          }}
        >
          <Form.Item
            name="email"
            label="email"
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="password"
            label="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm password"
            label="Confirm password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button type="primary" className="form-register--submit" size="large" htmlType="submit">
            Create password
          </Button>
          {/*}
       <p className="or">or</p>
       <Button size="large" type="primary">Sign in with Google</Button>
        {*/}
          <p className="form-register--legal">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel enim quam. Nulla quis
            orci vel massa efficitur consequat ut vitae nunc. Class aptent taciti sociosqu ad litora
            torquent per conubia nostra, per inceptos himenaeos. Aliquam ultrices, lectus non
            faucibus viverra, orci nisi vestibulum ipsum, eu finibus enim ex eget nulla.
          </p>
        </Form>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export { AcceptInvite };
