import { MapLayer } from '../configs/component-types';

/**
 * Reset property layerBefore on each layer
 *
 * @param layers
 * @returns
 */
export const resetLayersPosition = (layers: MapLayer[], lookup?: string): MapLayer[] => {
  layers.forEach((layer) => {
    if (lookup) {
      if (layer.layerBefore === lookup) {
        layer.layerBefore = '';
      }
    } else {
      layer.layerBefore = '';
    }

    if (layer.children && layer.children.length) {
      resetLayersPosition(layer.children, lookup);
    }
  });

  return layers;
};
