import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../configs/env';
import user from '../../../../../reducers/reducerUser';

const SpaceUsers = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const service = new AkukoAPIService(IDENTITY_API, `user/space/${props.spaceId}`);
    service.list().then((users) => {
      props.actionAccountUsersGet(users);
      setUsers(users);
    });
  }, [props.spaceId]);

  return (
    <div className="space-users">
      {users.map(
        (user, index) =>
          index < 11 && (
            <Popover key={index} content={user.email}>
              {user.image ? (
                <div
                  className="space-user"
                  style={{ backgroundImage: `url(https://assets.akuko.io/${user.image})` }}
                ></div>
              ) : (
                <div className="space-user"></div>
              )}
            </Popover>
          )
      )}
      {users.length > 10 && <div className="more-users">+ {users.length - 10} more</div>}
    </div>
  );
};

export default SpaceUsers;
