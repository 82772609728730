import { Dictionary } from '@onaio/utils/dist/types/types';
import { message } from 'antd';
import axios from 'axios';
import { UPLOADS_API } from '../../../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../../../configs/constants';

export const imageUploadService = async (file: Blob | string): Promise<string | undefined> => {
  const formData = new FormData();
  formData.append('file', file);
  let imageId;
  await axios
    .post(`${UPLOADS_API}images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((data: Dictionary) => {
      imageId = data?.data?.image_id;
    })
    .catch((error) => {
      message.error(error.message || error?.response?.data?.error || ERROR_GENERIC);
    });

  return imageId;
};

export const imageResizeService = async (image: Dictionary): Promise<string | undefined> => {
  const { id, dimensions } = image;
  const { width, height } = dimensions;
  let imageId;
  await axios
    .get(`${UPLOADS_API}images/${width}x${height}/${id}`)
    .then(({ data: { image_id } }) => {
      imageId = image_id;
    })
    .catch((error) => {
      message.error(error.message || error?.response?.data?.error || ERROR_GENERIC);
    });

  return imageId;
};

export default {
  imageUploadService,
  imageResizeService,
};
