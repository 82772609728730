/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { connect, useStore, useDispatch } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
//import ReactMapboxGl, { ScaleControl, Source, Layer, Popup } from 'react-mapbox-gl';
import Map, {
  ScaleControl,
  Source,
  Layer,
  Popup,
  ViewState,
  GeolocateControl,
  NavigationControl,
} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import MapFilterControl from '../Filters/MapFilterControl';
import {
  buildLayers,
  getLayerIcons,
  layersToRenderAsGeoJSON,
  getRawGeojsons,
  interactiveLayers,
  getUpdatedLayer,
} from './helpers';
import { mapHasToggleLayers, getTilesets } from '../../helpers';
import { getPopupContent, getStyle, mapLayerControl } from '../../helpers';
import {
  actionMapEventAdd,
  actionComponentMapLayerFilterEdit,
  actionComponentMapLayerVisibleEdit,
  actionComponentMapTilesetIdEdit,
  actionComponentMapLayerBuildGeojson,
  actionComponentMapLayerFetchGeojson,
  actionComponentMapLayerGeojsonEdit,
  actionComponentMapStoreAdd,
  actionComponentMapStyleGet,
} from '../../actions';
import {
  actionComponentZoomEdit,
  actionComponentLatitudeEdit,
  actionComponentLongitudeEdit,
  actionComponentBearingEdit,
  actionComponentPitchEdit,
  actionComponentSourceQuery,
  actionComponentFilterEdit,
  actionComponentEventAdd,
} from '../../../actions';
import { Button, message } from 'antd';
import { getDataForLayers, getGeojsonLayers } from './helpers';
import { Dictionary } from '@onaio/utils';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MapComponent, MapLayer } from '../../../../../configs/component-types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
(mapboxgl as any).workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mapDispatchToProps = {
  actionMapEventAdd,
  actionComponentFilterEdit,
  actionComponentZoomEdit,
  actionComponentLatitudeEdit,
  actionComponentLongitudeEdit,
  actionComponentBearingEdit,
  actionComponentPitchEdit,
  actionComponentMapLayerVisibleEdit,
  actionComponentMapLayerFilterEdit,
  actionComponentMapTilesetIdEdit,
  actionComponentMapLayerFetchGeojson,
  actionComponentMapLayerGeojsonEdit,
  actionComponentSourceQuery,
  actionComponentMapStoreAdd,
  actionComponentMapStyleGet,
  actionComponentEventAdd,
};
//
interface MapInstanceProps {
  component: MapComponent;
  data: Dictionary;
  postSources: Dictionary;
  index: number;
  actionComponentLatitudeEdit: typeof actionComponentLatitudeEdit;
  actionComponentLongitudeEdit: typeof actionComponentLongitudeEdit;
  actionComponentZoomEdit: typeof actionComponentZoomEdit;
  actionComponentBearingEdit: typeof actionComponentBearingEdit;
  actionComponentPitchEdit: typeof actionComponentPitchEdit;
  actionComponentMapLayerFetchGeojson: typeof actionComponentMapLayerFetchGeojson;
  actionComponentMapLayerGeojsonEdit: typeof actionComponentMapLayerGeojsonEdit;
  actionComponentMapTilesetIdEdit: typeof actionComponentMapTilesetIdEdit;
  actionComponentEventAdd: typeof actionComponentEventAdd;
  actionComponentMapStoreAdd: typeof actionComponentMapStoreAdd;
  actionComponentMapStyleGet: typeof actionComponentMapStyleGet;
  actionComponentMapLayerVisibleEdit: typeof actionComponentMapLayerVisibleEdit;
  config: any;
  edit: any;
  post: any;
}

const mapStateToProps = (state: Dictionary, ownProps: { index: number }) => {
  if (state) {
    return {
      edit: state.post.edit,
      config: state.config,
      user: state.user,
      data: state.post.data,
      component: state.post.components[ownProps.index],
      account: state.post.account_id,
      postSources: state.post.sources,
      sources: state.sources,
      events: state.post.events,
      post: state.post,
    };
  } else {
    return null;
  }
};

export interface prevLayerRefProps {
  prevLayers: MapLayer[];
}

const MapInstance = (props: MapInstanceProps) => {
  const { component } = props;
  const dispatch = useDispatch();
  const {
    layers,
    styleOption,
    styleColor,
    mapStyle,
    darkMode,
    defaultZoom,
    defaultLongitude,
    defaultLatitude,
    longitude,
    latitude,
    zoom,
    height,
    pitch,
    bearing,
    tour,
    zoomControl,
    locateControlHorizontalPosition,
    locateControlVerticalPosition,
    zoomControlHorizontalPosition,
    zoomControlVerticalPosition,
    enableScrollZoom,
    projection,
    terrain,
    locateControl,
    scaleBar,
    fogColor,
    highColor,
    spaceColor,
  } = component;

  const { postSources } = props;
  const params = useParams<Dictionary>();
  const [popupData, setPopupData] = useState<Dictionary>({
    layer: {},
    content: '',
    coordinates: '',
    visible: false,
  });
  const [dataIsReady, setDataIsReady] = useState<boolean>(false);
  const [geojson, setGeojson] = useState({ type: 'FeatureCollection', features: [] });
  const [geometryKey, setGeometryKey] = useState('');

  const [map, setMap] = useState<any>();
  const prevLayersRef = useRef<prevLayerRefProps>();
  // let mapLayers: any = [];
  const [mapLayers, setMapLayers] = useState([]);

  const [showLoader, setShowLoader] = useState(true);
  const [componentKey, setComponentKey] = useState<number>(0);
  const [tourItem, setTourItem] = useState(0);

  const store = useStore();

  // layers to render as geojson
  const geojsonLayers = layersToRenderAsGeoJSON(layers, postSources);
  let stringifiedGeojsonFilters = '';
  let stringifiedGeojsonSources = '';
  const compositeGeojsonLayers = [
    ...geojsonLayers.geojsonJoinLayers,
    ...geojsonLayers.geojsonPointLayers,
  ];
  if (compositeGeojsonLayers?.length > 0) {
    stringifiedGeojsonFilters = JSON.stringify(
      compositeGeojsonLayers.map((layer) => layer.filters)
    );
    stringifiedGeojsonSources = JSON.stringify(compositeGeojsonLayers.map((layer) => layer.source));
  }

  /**
   * Handle geojsons on initial load, new render as geojsons layers are added and when filters update
   */
  useEffect(() => {
    if (compositeGeojsonLayers?.length > 0) {
      // source update & filter update
      const updatedLayer = getUpdatedLayer(layers, prevLayersRef.current?.prevLayers);

      // initial run (checks against map layer)
      const unpreparedLayers = compositeGeojsonLayers.filter((layer: MapLayer) => {
        const mapLayerIds = mapLayers.map((mapLayer: JSX.Element) => mapLayer.props?.id);
        return !mapLayerIds.includes(layer.id);
      });

      if (updatedLayer.length > 0 || unpreparedLayers.length > 0) {
        setDataIsReady(false);

        const layersToBuild = compositeGeojsonLayers;
        getRawGeojsons(
          layersToBuild,
          postSources,
          props.post,
          props.actionComponentMapLayerFetchGeojson
        )
          .then(() => {
            // for layer that changed filter
            return getDataForLayers(
              props.component,
              layersToBuild,
              postSources,
              actionComponentMapLayerBuildGeojson,
              undefined,
              dispatch
            );
          })
          .then(() => {
            const currentStore = store.getState();
            return getGeojsonLayers(
              /* @ts-ignore */
              currentStore.post.data,
              layersToBuild,
              postSources,
              props.actionComponentMapLayerGeojsonEdit,
              setDataIsReady
            );
          })
          .catch((err) => {
            message.error(err);
          });
      }
    }
    prevLayersRef.current = { prevLayers: layers };
  }, [stringifiedGeojsonFilters, stringifiedGeojsonSources]);

  useMemo(() => {
    if (map && compositeGeojsonLayers.length > 0 && dataIsReady) {
      const mapboxLayers = buildLayers(layers, props, false, geojson, postSources, map);
      setMapLayers(mapboxLayers);
    }
  }, [dataIsReady]);

  useMemo(() => {
    /** On layer change hook
        render as geojson -  if  render as geojson layers exists and data is ready re-build layers
        tileset - if no geojson layers and map is ready
    */
    if (map && compositeGeojsonLayers.length > 0 && dataIsReady) {
      const mapboxLayers = buildLayers(layers, props, false, geojson, postSources, map);
      setMapLayers(mapboxLayers);
    } else if (map && compositeGeojsonLayers.length < 1) {
      const mapboxLayers = buildLayers(layers, props, false, geojson, postSources, map);
      setMapLayers(mapboxLayers);
    }
  }, [JSON.stringify(layers)]);

  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [props.config.drawerOpen, props.component.width, height, props.edit]);

  useEffect(() => {
    if (map) {
      window.akukoMaps[props.component.id] = map;
    }
  }, [map]);

  useEffect(() => {
    setShowLoader(true);
    if (layers) {
      getTilesets(layers, props.actionComponentMapTilesetIdEdit, props.index).then((res) => {
        setShowLoader(false);
      });
    }
  }, [props.component.key]);

  useEffect(() => {
    // Load svg icons
    if (map) {
      // https://docs.mapbox.com/mapbox-gl-js/api/map/#map.event:sourcedata
      map.on('styledata', () => {
        const icons = getLayerIcons(layers);
        icons.forEach((icon: Dictionary) => {
          const imageExists = map.listImages().includes(icon.id);
          if (!map.hasImage(icon.id) && !imageExists) {
            const customIcon = new Image(48, 48);
            customIcon.crossOrigin = 'anonymous';
            customIcon.src = `${icon.src}?q=${moment().format('MM-DD-YYYY')}`;
            customIcon.onload = () => map.addImage(icon.id, customIcon, { sdf: true });
          }
        });
      });
    }
  }, [map, layers]);

  useEffect(() => {
    if (map) {
      if (terrain) {
        // map.setTerrain({ source: 'mapbox-dem', exaggeration: 1 });
        map.setFog({
          range: [0.8, 8],
          color: fogColor || '#fff',
          'horizon-blend': 0.5,
          'high-color': highColor || '#fff',
          'space-color': spaceColor || '#fff',
          'star-intensity': 0.15,
        });
      } else {
        // map.setTerrain(null);
        map.setFog(null);
      }
    }
  }, [terrain, fogColor, highColor, spaceColor, mapStyle, projection]);

  useEffect(() => {
    if (map) {
      setComponentKey(Math.random());
    }
  }, [
    props.index,
    props.component.zoomControl,
    props.component.locateControl,
    props.component.locateControlHorizontalPosition,
    props.component.locateControlVerticalPosition,
    props.component.zoomControlHorizontalPosition,
    props.component.zoomControlVerticalPosition,
  ]);
  return (
    <>
      {showLoader && (
        <div className="map-loader">
          <LoadingOutlined />
        </div>
      )}
      {props.component.visible !== false && showLoader === false && (
        <Map
          ref={(ref) => {
            if (!map) {
              ref && setMap(ref.getMap());
            }
          }}
          initialViewState={{
            longitude: longitude || 0,
            latitude: latitude || 0,
            zoom: zoom || 0,
            pitch: pitch || 0,
            bearing: bearing || 0,
          }}
          reuseMaps={true}
          key={componentKey}
          scrollZoom={false}
          transformRequest={(url) => {
            if (url.includes('https://tiles.api.akuko.io')) {
              return {
                url: url,
                credentials: 'include',
              };
            } else {
              return {
                url: url,
              };
            }
          }}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          //eslint-disable-next-line
          /* @ts-ignore */
          projection={projection || 'mercator'}
          //eslint-disable-next-line
          /* @ts-ignore */
          mapStyle={getStyle(styleOption, styleColor, mapStyle, darkMode)}
          onLoad={(e: any) => {
            if (compositeGeojsonLayers.length < 1) {
              const mapboxLayers = buildLayers(layers, props, false, geojson, postSources, map);
              setMapLayers(mapboxLayers);
            }
          }}
          onMouseDown={(e: any) => {
            if (map && layers.length > 0) {
              const features = map.queryRenderedFeatures(e.point);
              let clickedLayer: MapLayer | undefined;
              if (features?.[0]?.layer?.id) {
                clickedLayer = layers.find((layer: any) => layer.id === features?.[0]?.layer?.id);
              }
              if (clickedLayer && clickedLayer.hasPopup === true) {
                const popupConfigs = {
                  componentIndex: props.index,
                  parentIndex: props.index,
                  cardIndex: 0,
                  componentId: props.component.id,
                };
                const popup = getPopupContent(
                  map,
                  e,
                  clickedLayer,
                  clickedLayer.id,
                  postSources[clickedLayer.source],
                  popupConfigs
                );
                setPopupData({
                  content: popup.content,
                  layer: clickedLayer,
                  visible: popup.visible,
                  coordinates: popup.coordinates,
                });
              }
              if (features.length > 0) {
                const clickedLayer = layers.filter(
                  (layer: any) => layer.id === features[0]?.layer.id
                );
                e.componentId = props.component.id;
                if (clickedLayer[0]) {
                  props.actionComponentEventAdd({
                    type: 'onLayerClick',
                    event: {
                      lngLat: e?.lngLat,
                      point: e?.point,
                      // not certain we need target for this
                      target: {
                        checked: e?.target?.checked,
                        key: e.key, // horizontal menu key
                        target: e?.target?.outerHTML,
                      },
                    },
                    componentId: props.component.id,
                    layer: clickedLayer[0],
                    feature: features[0],
                  });
                }
              }
            }
          }}
          onStyleData={(event) => {
            window.akukoMaps[props.component.id] = map;
            if (map && props.component?.terrain) {
              // map.setTerrain({ source: 'mapbox-dem', exaggeration: 1 });
              map.setFog({
                range: [0.8, 8],
                color: fogColor || '#fff',
                'horizon-blend': 0.5,
                'high-color': highColor || '#fff',
                'space-color': spaceColor || '#fff',
                'star-intensity': 0.15,
              });
            }
          }}
          // onMoveEnd={(evt) => {
          //   setViewState(evt.viewState);
          // }}
          style={{
            height: height || 500,
            width: '100%',
            background: component.styleColor,
          }}
          interactiveLayerIds={interactiveLayers(layers)}
        >
          {locateControl === true && (
            <GeolocateControl
              position={`${locateControlVerticalPosition || 'top'}-${
                locateControlHorizontalPosition || 'right'
              }`}
              // When active the map will receive updates to the device's location as it changes.
              trackUserLocation={true}
              // Draw an arrow next to the location dot to indicate which direction the device is heading.
              showUserHeading={true}
            />
          )}
          {zoomControl !== false && (
            <NavigationControl
              position={`${zoomControlVerticalPosition || 'top'}-${
                zoomControlHorizontalPosition || 'right'
              }`}
            />
          )}

          {popupData.coordinates && popupData.visible === true && (
            <Popup
              style={{ width: `${popupData.layer.popupWidth}px` }}
              className={popupData.layer?.contextFromPopup ? 'withLayout' : ''}
              longitude={popupData.coordinates?.lng}
              latitude={popupData.coordinates?.lat}
              closeButton={true}
              anchor="top"
              closeOnClick={false}
              onClose={() =>
                setPopupData({
                  ...popupData,
                  visible: false,
                })
              }
            >
              {popupData.content}
            </Popup>
          )}

          <div className="map-controls">
            <div className="content">
              {mapHasToggleLayers(layers) &&
                mapLayerControl(layers, props.actionComponentMapLayerVisibleEdit, props.index)}
              {layers?.map(
                (layer: MapLayer, index: number) =>
                  layer.visible &&
                  layer.filters?.map(
                    (filter: Dictionary, filterIndex: number) =>
                      filter.expose && (
                        <MapFilterControl
                          key={filterIndex}
                          layer={layer}
                          primaryLayerIndex={index}
                          filter={filter}
                          filterIndex={filterIndex}
                          {...props}
                        />
                      )
                  )
              )}
            </div>
          </div>

          {mapLayers}

          {scaleBar && (
            <div className="scale-control">
              <ScaleControl position={'bottom-right'} />
            </div>
          )}
        </Map>
      )}
    </>
  );
};

const MapInstanceContainer = connect(mapStateToProps, mapDispatchToProps)(MapInstance);

export default MapInstanceContainer;
