import produce from 'immer';
import { ENV } from '../configs/env';

const user = (state = { authenticated: true }, action) => {
  let nextState;
  switch (action.type) {
    case 'USER_AUTH':
      nextState = produce(state, (draftState) => {
        draftState.authenticated = true;
        draftState.uuid = action.data.uuid;
        draftState.name = action.data.name;
        draftState.spaces = action.data.spaces;
        draftState.email = action.data.email;
        draftState.handle = action.data.handle;
        draftState.tagline = action.data.tagline;
        draftState.accounts = action.data.accounts;
      });
      return nextState;
    case 'USER_IS_AUTH':
      if (action.data && action.data.valid === false) {
        nextState = produce(state, (draftState) => {
          draftState.authenticated = false;
        });
        return nextState;
      }
      if (action.data && action.data.valid === true) {
        nextState = produce(state, (draftState) => {
          draftState.authenticated = true;
        });
        return nextState;
      }
      return state;
    case 'CURRENT_USER_GET':
      nextState = produce(state, (draftState) => {
        if (action.data) {
          draftState.authenticated = true;
          draftState.uuid = action.data.uuid;
          draftState.email = action.data.email;
          draftState.image = action.data.image;
          draftState.handle = action.data.handle;
          draftState.name = action.data.name;
          draftState.spaces = action.data.spaces;
          draftState.tagline = action.data.tagline;
          draftState.accounts = action.data.accounts;
        }
      });
      return nextState;
    case 'USER_IMAGE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.image = action.data.value;
      });
      return nextState;
    case 'USER_ACTIVE_ACCOUNT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.activeAccount = action.data.account;
      });
      return nextState;
    case 'USER_ADD':
      return state;
    case 'USER_LOGOUT':
      nextState = produce(state, (draftState) => {
        localStorage.removeItem('email');
        localStorage.removeItem('account');
        if (ENV === 'prod') {
          window.location = 'https://app.akuko.io/';
        } else {
          window.location = '/user/login';
        }
      });
      return nextState;
    default:
      return state;
  }
};

export default user;
