import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit, actionPostPropertyEdit } from '../../../actions';
import { SymbolInput } from '../SymbolInput';
import { Icon as IconType } from '../SymbolPicker';
import { getComponentIndex } from '../../../../../reducers/selectors/post';

export interface IconInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}



const IconInput: React.FC<IconInputProps> = (props: IconInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents } = item;
  const post = useSelector((state: any) => state.post);
  const [value, setValue] = useState()

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (componentIndex && !parents) {
      setValue(post.components[componentIndex][property])
    }
    if (item.entity === 'post') {
      if (post && post.color) {
        setValue(post[property]);
      }
    } 
  }, [post, parents, itemIndex, childIndex, componentIndex])


  const symbolInputProps = {
    symbol: value,
    handleSymbolEdit: (value: IconType) => {
      dispatch(actionPostComponentSettingEdit({
        parents: parents,
        property: property,
        componentIndex: componentIndex,
        itemIndex: itemIndex,
        childIndex: childIndex,
        value: value
      }))
    },
    enableDelete: true,
  };
  
  return(
    <>
     { label &&
     <label>{label}</label>
     }
     <SymbolInput {...symbolInputProps} />
    </>
  )
}

export { IconInput };