/* Post helpers */
import React from 'react';
import { AkukoAPIService } from '../../services/serviceClass';
import { POSTS_API } from '../../configs/env';
import { message, Select } from 'antd';
import { getUniqueValues } from '../App/ColorSettings/helpers';
import moment from 'moment';
import { actionComponentFilterEdit } from './actions';
import { genericDateFormatter } from './utils';
import { getContrast } from '../App/ColorSettings/helpers';
import { actionComponentAdd } from './actions';
import { makeId } from '../../reducers/helpers';
const { Option } = Select;
import { useHistory } from 'react-router';
import { ERROR_GENERIC } from '../../configs/constants';

export const handlePostType = (post, dispatch) => {
  //document.body.classList.add('drawer-open');
  const hasMap = post.components.find((item) => item.template);
  const id = makeId();
  if (hasMap === undefined) {
    dispatch(
      actionComponentAdd({
        index: post.components.length + 1,
        value: {
          id: id,
          type: 'layout',
          width: 'large',
          layoutId: '24',
          positionVertical: 'top',
          positionHorizontal: 'right',
          template: 'map-post',
          percentWidth: 100,
          percentHeight: 100,
          widthMetric: '%',
          heightMetric: '%',
          name: 'Map wrapper layout',
          rows: 1,
          columns: 2,
          tabs: [],
          collapse: [],
        },
      })
    );
    dispatch(
      actionComponentAdd({
        index: post.components.length,
        value: {
          layout: id,
          name: 'Map',
          region: '1-a',
          template: 'map-post',
          type: 'map',
          width: 'large',
          layers: [],
        },
      })
    );
    dispatch(
      actionComponentAdd({
        index: post.components.length + 2,
        value: {
          type: 'layout',
          name: 'Sidebar',
          width: 'large',
          layoutId: '24',
          template: 'map-post',
          positionVertical: 'top',
          positionHorizontal: 'right',
          verticalValue: 40,
          horizontalValue: 40,
          paddingTop: 20,
          paddingBottom: 10,
          paddingRight: 20,
          paddingLeft: 20,
          marginTop: 0,
          marginRight: 0,
          percentWidth: 30,
          widthMetric: '%',
          heightMetric: 'auto',
          layoutShadow: true,
          layoutBackgroundColor: '#ffffff',
          rows: 3,
          columns: 2,
          tabs: [],
          collapse: [],
        },
      })
    );
  }
};

export const addFonts = (post, space) => {
  const obj = {};
  post.components.forEach((item) => {
    if (item.fontFamily) {
      obj[item.fontFamily] = item.fontFamily;
    }
    if (item.titleFontFamily) {
      obj[item.titleFontFamily] = item.titleFontFamily;
    }
  });
  let fontString = '';
  Object.keys(obj).forEach((key) => {
    fontString += `&family=${key}:wght@400;700`;
  });
  if (space.config?.headingFontFamily) {
    fontString += `&family=${space.config?.headingFontFamily}:wght@${
      space.config?.headingFontWeight || '400'
    }`;
  }
  if (post.config?.headingFontFamily) {
    fontString += `&family=${post.config?.headingFontFamily}:wght@${
      post.config?.headingFontWeight || '400'
    }`;
  }
  if (post.config?.bodyFontFamily) {
    fontString += `&family=${post.config?.bodyFontFamily}:wght@${
      post.config?.bodyFontWeight || '400'
    }`;
  }
  if (post.config?.fontFamily) {
    fontString += `&family=${post.config?.fontFamily}:wght@${
      post.config?.fontWeight || '400'
    }`;
  }
  if (space.config?.bodyFontFamily) {
    fontString += `&family=${space.config?.bodyFontFamily}:wght@${
      space.config?.bodyFontWeight || '400'
    }`;
  }
  if (space.config?.fontFamily) {
    fontString += `&family=${space.config?.fontFamily}:wght@${
      space.config?.fontWeight || '400'
    }`;
  }
  if (fontString !== '') {
    const currentFonts = document.getElementById('fontFamily');
    if (!currentFonts) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.id = 'fontFamily';
      link.href = `https://fonts.googleapis.com/css2?${fontString}&display=swap`;
      document.head.appendChild(link);
    }
    if (currentFonts) {
      const newLink = `https://fonts.googleapis.com/css2?${fontString}&display=swap`;
      if (newLink !== currentFonts.href) {
        document.getElementById('fontFamily').remove();
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.id = 'fontFamily';
        link.href = `https://fonts.googleapis.com/css2?${fontString}&display=swap`;
        document.head.appendChild(link);
      }
    }
  }
};

export const addStyles = (post, space) => {
  /*
  const styles = document.head.getElementsByClassName('inline-css');
  const arr = Array.from(styles);
  if (arr?.length > 0) {
    arr?.forEach((el) => document.head.removeChild(el));
  }
  document.body.classList.add('page-post');
  if (space?.config && post.space_id === space.id) {
    const style = document.createElement('style');
    style.setAttribute('class', 'inline-css');
    style.innerHTML = `
    .post--content h1,
    .post--content h2,
    .post--content h3,
    .post--content h4,
    .post--content h5 {
      font-family: ${space.config?.font?.headingFontFamily || 'Poppins'} !important;
      font-weight:  ${space.config?.font?.headingFontWeight || 600};
      color: ${space.config?.heading_color || '#111111'} !important;
    }
      `;
    document.head.appendChild(style);
  } else {
    const style = document.createElement('style');
    style.setAttribute('class', 'inline-css');
    style.innerHTML = `
    .post--content h1,
    .post--content h2,
    .post--content h3,
    .post--content h4,
    .post--content h5 {
      font-family: 'Poppins', sans-serif !important;
      font-weight: 600;
    }
      `;
    document.head.appendChild(style);
  }
  */
  return false;
};

/**
 * @param {*} location // component props.location
 */
export const getQueryParams = (location) => {
  if (location?.hash) {
    const paramsRaw = location.hash.split('#');
    const paramsArray = paramsRaw[1].split('&');
    const params = paramsArray.map((item) => item.split('='));
    return params;
  }
  if (location?.search) {
    const paramsRaw = location.search.split('?');
    const paramsArray = paramsRaw[1].split('&');
    const params = paramsArray.map((item) => item.split('='));
    return params;
  }
};

/**
 * @param {*} props // component props
 */
export const applyFiltersFromUrl = (location, post, dispatch) => {
  const params = getQueryParams(location);
  if (params) {
    params.forEach((param) => {
      post.components.forEach((item, index) => {
        if (item.type === 'map') {
          item.layers.forEach((layer, layerIndex) => {
            if (layer.filters) {
              layer.filters.forEach((filter, filterIndex) => {
                if (filter[1] === param[0]) {
                  dispatch(
                    actionComponentFilterEdit({
                      componentIndex: index,
                      filterIndex: filterIndex,
                      propertyIndex: 2,
                      layerIndex: layerIndex,
                      value: decodeURI(param[1]),
                    })
                  );
                }
              });
            }
          });
        } else {
          if (item.filters) {
            item.filters.forEach((filter, filterIndex) => {
              if (filter[1] === param[0]) {
                dispatch(
                  actionComponentFilterEdit({
                    componentIndex: index,
                    filterIndex: filterIndex,
                    propertyIndex: 2,
                    value: decodeURI(param[1]),
                  })
                );
              }
            });
          }
        }
      });
    });
  }
};

/**
 * @param {*} item // the dimension / measure config (from source)
 * @param {*} value // the value to transform
 * @param {*} source // the source object
 */
export const formatValue = (item, value, source) => {
  // If the setting 'displayZeroValues' is set and is 'false', return empty
  // string for 0 values
  if (typeof value === 'string') {
    value = value.trim();
  }

  if (
    Number(value) === 0 &&
    item?.displayZeroValues !== undefined &&
    !item.displayZeroValues
  ) {
    return '';
  }

  // if measure, key is item.name
  // if dimension, key is item.value

  const key = item.seriesValue || (item.name ? item.name : item.value);
  const label = source?.labels?.[key];
  let prefix = label?.prefix || '';
  let suffix = label?.suffix || '';

  if (label?.format) {
    const decimals = getDecimals(Number(label.format));
    value = Number(value).toFixed(decimals);
    value = numberWithCommas(value);
  }

  // for table item
  if (item.dateFormat) {
    return genericDateFormatter(item.dateFormat, value);
  }
  return `${prefix}${value}${suffix}`;
};

const getDecimals = (n) => {
  if (isNaN(n) || Math.floor(n.valueOf()) === n.valueOf()) return 0;
  return n.toString().split('.')[1].length || 0;
};

export const kFormatter = (n) => {
  if (n === null) {
    return 0;
  }
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

export const getMeasureName = (field, source, props) => {
  let title = field;
  props.postSources[source].measures.forEach((item) => {
    if (item.name === field) {
      title = item.title;
    }
  });
  return title;
};

export const getComponentDimensions = (component) => {
  const dimensions = [];

  if (component.type === 'chart') {
    const dimensions = [];

    const {
      series,
      xAxis,
      context,
      cube,
      bubbleProperties,
      customToolTipProperties,
    } = component;

    if (!series || series.length === 0) {
      return [];
    }

    const axis =
      Object.keys(xAxis).length !== 0 && (xAxis.value || xAxis.value === '')
        ? xAxis.value
        : xAxis;

    if (series) {
      series
        .filter((item) => item.value_type === 'dimension')
        .forEach((el) => dimensions.push(`${cube}.${el.value}`));

      series
        .filter((item) => item.groupBy_type === 'dimension')
        .forEach((el) => dimensions.push(`${cube}.${el.groupBy}`));
    }

    if (bubbleProperties) {
      const { source_type, bubbleSize } = bubbleProperties;
      if (bubbleSize) {
        if (source_type === 'dimension') {
          dimensions.push(`${cube}.${bubbleSize}`);
        }
      }
    }

    if (customToolTipProperties) {
      const { source_type, customToolTipField } = customToolTipProperties;
      if (source_type === 'dimension') {
        dimensions.push(`${cube}.${customToolTipField}`);
      }
    }

    if (axis) {
      const dimension = `${cube}.${axis}`;
      if (dimensions.indexOf(dimension) === -1) {
        dimensions.push(dimension);
      }
    }

    /*
    if (sortField) {
      const dimension = `${cube}.${sortField}`;
      if (dimensions.indexOf(dimension) === -1) {
        dimensions.push(dimension);
      }
    }
    */

    if (context) {
      const dimension = `${cube}.${context}`;

      if (dimensions.indexOf(dimension) === -1) {
        dimensions.push(dimension);
      }
    }

    return dimensions;
  }

  const { properties, context, cube, colorField, dimensionImageUrl } =
    component;

  if (context) {
    dimensions.push(`${cube}.${context}`);
  }

  if (!context && dimensionImageUrl) {
    dimensions.push(`${cube}.${dimensionImageUrl}`);
  }

  if (colorField) {
    const dimension = `${cube}.${colorField}`;
    if (dimensions.indexOf(dimension) === -1) {
      dimensions.push(dimension);
    }
  }
  if (properties) {
    properties
      .filter((property) => property.type === 'dimension')
      .forEach((el) => {
        dimensions.push(`${cube}.${el.value}`);
      });
  }

  return dimensions;
};
// NB: refactor dimension and measure getters to use same utility
export const getLayerDimensions = (layer, source) => {
  const { geometryIndex } = layer;
  const { properties, sourceJoin, type, lat, lng } =
    source.geometries[geometryIndex];
  const { cube, dimensions } = source;
  const layerDimensions = [];
  // use global filters if they exist
  const activeFilters = layer.filters;
  // if layer has no filter add source join as the only dimension
  if (
    !(
      activeFilters &&
      activeFilters.find((filter) => filter[2] && filter[2].length)
    ) &&
    type === 'join'
  ) {
    layerDimensions.push(`${cube}.${sourceJoin}`);
  } else if (
    // !(activeFilters && activeFilters.find((filter) => filter[2] && filter[2].length)) &&
    type === 'latLng'
  ) {
    layerDimensions.push(`${cube}.${lat}`);
    layerDimensions.push(`${cube}.${lng}`);
  }

  if (properties) {
    properties.forEach((el) => {
      if (type === 'join') {
        layerDimensions.push(`${cube}.${sourceJoin}`);
      }
      if (dimensions.map((dim) => dim.value).includes(el)) {
        layerDimensions.push(`${cube}.${el}`);
      }
    });
  }

  if (layer.colorField && isDimension(source, layer.colorField)) {
    layerDimensions.push(`${cube}.${layer.colorField}`);
  }

  return layerDimensions;
};

export const getLayerMeasures = (layer, source) => {
  const { geometryIndex } = layer;
  const { properties } = source.geometries[geometryIndex];
  const { cube, measures } = source;
  const layerMeasures = [];
  if (properties) {
    properties?.forEach((el) => {
      if (measures?.map((measure) => measure.name).includes(el)) {
        layerMeasures.push(`${cube}.${el}`);
      }
    });
  }
  if (layer.colorField && isMeasure(source, layer.colorField)) {
    layerMeasures.push(`${cube}.${layer.colorField}`);
  }
  return layerMeasures;
};

const isDimension = (source, item) => {
  const find = source.dimensions.find((dimension) => dimension.value === item);
  if (find) {
    return true;
  }
  return false;
};

const isMeasure = (source, item) => {
  const find = source.measures.find((measure) =>
    [measure.value, measure.name].includes(item)
  );
  if (find) {
    return true;
  }
  return false;
};

export const getLayerFilters = (array, source) => {
  const { cube } = source;
  const filters = [];
  array.forEach((item) => {
    if (item[0] === 'notNull' && item[1]) {
      filters.push({
        member: `${cube}.${item[1]}`,
        operator: 'set',
      });
    } else if (item[0] === 'isNull' && item[1]) {
      filters.push({
        member: `${cube}.${item[1]}`,
        operator: 'notSet',
      });
    } else {
      let operator;

      if (item[0] === '==') {
        operator = 'equals';
      }
      if (item[0] === '>') {
        operator = 'gt';
      }
      if (item[0] === '<') {
        operator = 'lt';
      }
      if (item[0] === '!=') {
        operator = 'notEquals';
      }
      if (item[0] === '<=') {
        operator = 'lte';
      }
      if (item[0] === '>=') {
        operator = 'gte';
      }
      if (item[0] === 'contains') {
        operator = 'contains';
      }

      if (item[0] === 'beforeDate') {
        operator = 'beforeDate';
      }
      if (item[0] === 'afterDate') {
        operator = 'afterDate';
      }
      if (item[0] === 'notInDateRange') {
        operator = 'notInDateRange';
      }
      if (item[0] === 'inDateRange') {
        operator = 'inDateRange';
      }

      if (
        Array.isArray(item[2]) &&
        item[2] &&
        !['notInDateRange', 'inDateRange'].includes(operator)
      ) {
        if (item[2].length > 0) {
          filters.push({
            member: `${cube}.${item[1]}`,
            operator: operator,
            values: item[2].map((item) => String(item)),
          });
        }
      } else {
        if (
          item[2] !== undefined &&
          !['notInDateRange', 'inDateRange'].includes(operator)
        ) {
          filters.push({
            member: `${cube}.${item[1]}`,
            operator: operator,
            values: [String(item[2])],
          });
        }
      }
      if (
        ['notInDateRange', 'inDateRange'].includes(operator) &&
        ((Array.isArray(item[3]) && item[3]?.length) ||
          (Array.isArray(item[4]) && item[4]?.length))
      ) {
        if (Array.isArray(item[3]) && Array.isArray(item[4])) {
          const from = item[3]?.length
            ? item[3]?.map((item) => String(item))
            : item[4]?.map((item) => String(item));
          const to = item[4]?.length
            ? item[4]?.map((item) => String(item))
            : item[3]?.map((item) => String(item));
          filters.push({
            member: `${cube}.${item[1]}`,
            operator: operator,
            values: [...from, ...to],
          });
        } else {
          filters.push({
            member: `${cube}.${item[1]}`,
            operator: operator,
            values: [...item[3], item[4]],
          });
        }
      } else {
        if (
          ['notInDateRange', 'inDateRange'].includes(operator) &&
          ((item[3] !== undefined && item[3]?.length) ||
            (item[4] !== undefined && item[4]?.length))
        ) {
          const before = item[3]?.length ? item[3] : item[4];
          const after = item[4]?.length ? item[4] : item[3];
          filters.push({
            member: `${cube}.${item[1]}`,
            operator: operator,
            values: [before, after],
          });
        }
      }
    }
  });
  return filters;
};

export const getComponentMeasures = (component) => {
  const measures = [];

  if (component.type === 'chart') {
    const { series, xAxis, cube, bubbleProperties } = component;

    if (series) {
      series
        .filter((item) => item.value_type === 'measure')
        .forEach((el) => measures.push(`${cube}.${el.value}`));

      series
        .filter((item) => item.groupBy_type === 'measure')
        .forEach((el) => measures.push(`${cube}.${el.groupBy}`));
    }

    if (bubbleProperties) {
      const { source_type, bubbleSize } = bubbleProperties;
      if (bubbleSize) {
        if (source_type === 'measure') {
          measures.push(`${cube}.${bubbleSize}`);
        }
      }
    }

    if (xAxis && xAxis.type === 'measure') {
      const measure = `${cube}.${xAxis.value}`;

      if (measures.indexOf(measure) === -1) {
        measures.push(measure);
      }
    }

    return measures;
  }

  const { properties, cube } = component;

  if (properties) {
    properties
      .filter((property) => property.type === 'measure')
      .forEach((el) => {
        measures.push(`${cube}.${el.value}`);
      });
  }

  return measures;
};

export const getFilters = (array, queryName) => {
  const filters = [];
  array.forEach((item) => {
    if (item[0] === 'notNull' && item[1]) {
      filters.push({
        member: `${queryName}.${item[1]}`,
        operator: 'set',
      });
    } else if (item[0] === 'isNull' && item[1]) {
      filters.push({
        member: `${queryName}.${item[1]}`,
        operator: 'notSet',
      });
    } else {
      let operator;

      if (item[0] === '==') {
        operator = 'equals';
      }
      if (item[0] === '>') {
        operator = 'gt';
      }
      if (item[0] === '<') {
        operator = 'lt';
      }
      if (item[0] === '!=') {
        operator = 'notEquals';
      }
      if (item[0] === '<=') {
        operator = 'lte';
      }
      if (item[0] === '>=') {
        operator = 'gte';
      }
      if (item[0] === 'contains') {
        operator = 'contains';
      }

      if (item[0] === 'beforeDate') {
        operator = 'beforeDate';
      }
      if (item[0] === 'afterDate') {
        operator = 'afterDate';
      }
      if (item[0] === 'notInDateRange') {
        operator = 'notInDateRange';
      }
      if (item[0] === 'inDateRange') {
        operator = 'inDateRange';
      }

      if (
        Array.isArray(item[2]) &&
        item[2] &&
        !['notInDateRange', 'inDateRange'].includes(operator)
      ) {
        if (item[2].length > 0) {
          filters.push({
            member: `${queryName}.${item[1]}`,
            operator: operator,
            values: item[2].map((item) => String(item)),
          });
        }
      } else {
        if (
          item[2] !== undefined &&
          !['notInDateRange', 'inDateRange'].includes(operator)
        ) {
          filters.push({
            member: `${queryName}.${item[1]}`,
            operator: operator,
            values: [String(item[2])],
          });
        }
      }
      if (
        ['notInDateRange', 'inDateRange'].includes(operator) &&
        ((Array.isArray(item[3]) && item[3]?.length) ||
          (Array.isArray(item[4]) && item[4]?.length))
      ) {
        const before = item[3]?.length
          ? item[3]?.map((item) => String(item))
          : item[4]?.map((item) => String(item));
        const after = item[4]?.length
          ? item[4]?.map((item) => String(item))
          : item[3]?.map((item) => String(item));
        filters.push({
          member: `${queryName}.${item[1]}`,
          operator: operator,
          values: [...before, ...after],
        });
      } else {
        if (
          ['notInDateRange', 'inDateRange'].includes(operator) &&
          ((item[3] !== undefined && item[3]?.length) ||
            (item[4] !== undefined && item[4]?.length))
        ) {
          const before = item[3]?.length ? item[3] : item[4];
          const after = item[4]?.length ? item[4] : item[3];
          filters.push({
            member: `${queryName}.${item[1]}`,
            operator: operator,
            values: [String(before), String(after)],
          });
        }
      }
    }
  });
  return filters;
};

export const getSourceQueryName = (id, sources) => {
  let queryName;
  sources.forEach((item) => {
    if (item.id === id) {
      queryName = item.queryName;
    }
  });
  return queryName;
};

export const getSourceIndex = (id, sources) => {
  let sourceIndex;
  sources.forEach((item, index) => {
    if (item.id === id) {
      sourceIndex = index;
    }
  });
  return sourceIndex;
};

export const getCheckedKeys = (childFilter, filters) => {
  let keys = [];
  filters.forEach((item) => {
    if (item[1] === childFilter) {
      keys = item[2];
    }
  });
  return keys;
};

export const getTreeChildren = (child, parent, value, data) => {
  if (child && parent) {
    const childValues = data.filter((row) => row[parent] === value);
    const uniqueChildren = getUniqueValues(childValues, child);
    const children = [];
    uniqueChildren.forEach((item) => {
      children.push({
        title: item,
        value: item,
        key: item,
        parent: value,
      });
    });
    return children;
  }
  return null;
};

export const getTreeData = (filter, settings, filterIndex, props) => {
  const component = props.post.components[props.index];
  const source = component.source;
  const data = props.post.data[source].slice();
  const parentFilter = filter[1];
  let childFilter = 0;
  if (settings.child && component.filters[settings.child]) {
    childFilter = component.filters[settings.child][1];
  }
  let parents = [];
  const tree = [];
  // get the parent values
  parents = getUniqueValues(data, parentFilter);
  // build the tree
  parents.forEach((value, index) =>
    tree.push({
      title: value,
      value: value,
      key: index,
      children: getTreeChildren(childFilter, parentFilter, value, data),
    })
  );
  return tree;
};

/**
 * @param {number} columnIndex
 * @param {object} props
 * @param {string} value
 */
export const replaceTokens = (component, field) => {
  let text = component[field];
  const regex = /{(.*?)}/g;
  const results = [...text.matchAll(regex)];
  results.forEach((result) => {
    if (result && result[1]) {
      // check filters for match
      if (component.filters && component.filters.length > 0) {
        component.filters.forEach((filter) => {
          if (filter[1] === result[1]) {
            text = text.replace(new RegExp(result[0], 'g'), filter[2]);
          }
        });
      }
      // check series for match
      if (result[1] === 'Series' && component.series && component.series[0]) {
        text = text.replace(
          new RegExp(result[0], 'g'),
          component.series[0].value[component.series[0].activeSeriesIndex]
        );
      }
    }
  });
  text = text.replace(/,/g, ', ');
  return `${text}`;
};

/**
 * Process component tokens
 *
 * @param {*} text text to replace tokens
 * @param {*} component post component
 * @param {*} dataRow data row pased to component
 * @returns {string} text with parsed tokens
 */
export const processTokens = (text, component, dataRow, source) => {
  const dimensionMatchDataRow = {};
  const dataRowKeys = Object.keys(dataRow);
  dataRowKeys.map((key) => {
    if (key.split('.')[1]) {
      dimensionMatchDataRow[key.split('.')[1]] = dataRow[key];
    }
    dimensionMatchDataRow[key] = dataRow[key];
  });

  const matches = text.match(/[^{}]+(?=})/g);

  if (!matches) return text;

  const parsedMatches = matches.map((token) =>
    token.replace('}', '').replace('{', '')
  );
  /** If token matches {foo} then it's a dimension/measure, get its value **/
  let tokenizedText = text;

  const tokenize = (tokenValue, dataValue) =>
    tokenizedText.replace(`{${tokenValue}}`, dataValue);
  parsedMatches.forEach((tokenValue) => {
    const propertyOptions = component?.properties?.find(
      (item) => item.value === tokenValue
    );
    const item =
      source?.measures?.find((measure) => measure.name === tokenValue) ||
      source?.dimensions?.find((dimension) => dimension.value === tokenValue);

    let value;

    if (dimensionMatchDataRow) {
      value = dimensionMatchDataRow[`${component.cube}.${tokenValue}`];
      value =
        value !== undefined ? value : dimensionMatchDataRow[`${tokenValue}`];
    }

    if (value !== undefined && item) {
      let dateFormatValueUpdate = value;
      // overwrite date value with
      // formatted equivalent if provided

      if (propertyOptions?.dateFormat) {
        dateFormatValueUpdate = genericDateFormatter(
          propertyOptions?.dateFormat,
          value
        );
      }
      const formattedValue = formatValue(item, dateFormatValueUpdate, source);
      tokenizedText = tokenize(tokenValue, formattedValue);
    }
  });
  return tokenizedText;
};

/**
 * @param {object} layer
 * @param {array} datace
 * @returns {object}
 */
export const getFilterValues = (filterItem, props) => {
  const filter = filterItem[1];
  const source = props.post.components[props.index].source;
  const values = getUniqueValues(props.post.data[source], filter);
  let type = typeof values[0];
  if (type === 'string') {
    const strip = values[0].replace(/\s/g, '');
    const isDate = moment(strip).isValid();
    if (isDate) {
      type = 'date';
      values.sort((a, b) => moment(a).format('x') - moment(b).format('x'));
    }
  }
  values.sort((a, b) => a - b);
  return values.map((item, index) => (
    <Option key={index} value={item}>
      {item}
    </Option>
  ));
};

/**
 * @param {array} layer
 * @param {object} props
 * @returns {object}
 */
export const getValues = (field, data) => {
  const obj = {};
  data.forEach((row) => {
    obj[row[field]] = row[field];
  });
  const array = [];
  Object.keys(obj).map((item) => {
    if (item !== '') {
      return array.push(item);
    }
    return null;
  });
  return array.sort();
};

/**
 * @param {number} columnIndex
 * @param {object} props
 * @param {string} value
 */
export const getActiveAccount = (props) => {
  // grab the active account from local storage
  const account = localStorage.getItem('account');
  if (account) {
    return account;
  }
  // use the activeAccount in the store
  if (props.user.activeAccount) {
    return props.user.activeAccount;
  }
  // else, use the first account
  return props.user.accounts[0].id;
};

export const addLegacyTitleComponent = (props) => {
  let hasTitle = false;
  if (props.post.components) {
    props.post.components.forEach((item) => {
      if (item.type === 'title') {
        hasTitle = true;
      }
    });
    if (hasTitle) {
      return false;
    } else {
      return true;
    }
  }
};

/**
 * @param {number} columnIndex
 * @param {object} props
 * @param {string} value
 */
export const isChartOrMap = (type) => {
  if (type === 'chart') {
    return true;
  }
  if (type === 'map') {
    return true;
  }
  return false;
};

/**
 * @param {number} columnIndex
 * @param {object} props
 * @param {string} value
 */
export const getCols = (props) => {
  const currentComponent = props.post.components[props.index];
  const nextComponent = props.post.components[props.index + 1];
  const nextNextComponent = props.post.components[props.index + 2];
  const prevComponent = props.post.components[props.index - 1];
  const prevPrevComponent = props.post.components[props.index - 2];

  if (
    isChartOrMap(currentComponent.type) &&
    nextComponent &&
    isChartOrMap(nextComponent.type) &&
    nextNextComponent &&
    isChartOrMap(nextNextComponent.type)
  ) {
    return 8;
  }
  if (
    isChartOrMap(currentComponent.type) &&
    prevComponent &&
    isChartOrMap(prevComponent.type) &&
    prevPrevComponent &&
    isChartOrMap(prevPrevComponent.type)
  ) {
    return 8;
  }
  if (
    isChartOrMap(currentComponent.type) &&
    prevComponent &&
    isChartOrMap(prevComponent.type) &&
    nextComponent &&
    isChartOrMap(nextComponent.type)
  ) {
    return 8;
  }
  if (
    isChartOrMap(currentComponent.type) &&
    nextComponent &&
    isChartOrMap(nextComponent.type)
  ) {
    return 12;
  }
  if (
    isChartOrMap(currentComponent.type) &&
    prevComponent &&
    isChartOrMap(prevComponent.type)
  ) {
    return 12;
  }
  return 24;
};

/**
 * @param {number} columnIndex
 * @param {object} props
 * @param {string} value
 */
export const getColumnSplit = (props) => {
  let cols = props.post.components[props.index].cols || 24;
  if (cols === 24) {
    return 12;
  }
  if (cols === 12) {
    return 8;
  }
  if (cols === 8) {
    return 6;
  }
  if (cols === 6) {
    return 4;
  }
  if (cols === 4) {
    return 24;
  }
};

export const numberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const sortIgnoreCase = (data, sortField, cube, sortOrder) => {
  // if non-numeric values exist sort in a case-insensitive manner

  if (data.length > 0 && sortField && cube && sortOrder) {
    const field = `${cube}.${sortField}`;
    const dataHasField = data.every((row) => row[field] !== undefined);
    const hasNumericData = data.find(
      (cardData) => cardData[field] && !isNaN(cardData[field])
    );

    if (!hasNumericData && dataHasField) {
      return [...data].sort((a, b) => {
        if (a[field] && b[field]) {
          return sortOrder === 'asc'
            ? a[field].localeCompare(b[field], undefined, {
                sensitivity: 'base',
              })
            : b[field].localeCompare(a[field], undefined, {
                sensitivity: 'base',
              });
        }
      });
    } else {
      // should be handled by cube order functionality
      return data;
    }
  }
  return data;
};

export const buildTimeDimension = (cube, dimensionValue, granularity) => {
  if (granularity) {
    return [
      {
        dimension: `${cube}.${dimensionValue}`,
        granularity: granularity,
      },
    ];
  }
  return [];
};

export const getDataRowCube = (dataRow) => {
  if (!dataRow) {
    return '';
  }
  let cube = '';
  Object.keys(dataRow)?.forEach((key) => {
    const split = key.split('.');
    if (key.includes('Cube_')) {
      cube = split[0];
    }
  });
  return cube;
};

export const getTimeDimension = (component) => {
  let timeDimensions = [];
  const { type, cube } = component;
  const granularProperty = component.properties?.find(
    (property) => property.granularity
  );
  switch (type) {
    case 'table':
      return buildTimeDimension(
        cube,
        granularProperty?.value,
        granularProperty?.granularity
      );
    case 'chart':
      return buildTimeDimension(
        cube,
        component.xAxis.value,
        component.granularity
      );
    case 'card':
      return buildTimeDimension(
        cube,
        granularProperty?.value,
        granularProperty?.granularity
      );
    case 'text':
      return buildTimeDimension(
        cube,
        granularProperty?.value,
        granularProperty?.granularity
      );
    default:
      return timeDimensions;
  }
};

export const filterBasedOnContext = (dataRow, data, component) => {
  let dataRowComparable =
    dataRow?.[
      `${getCardSource(dataRow, component?.context)}.${component?.context}`
    ];

  // check if dataRow comes from map popup

  if (component?.context && dataRow?.[component?.context]) {
    dataRowComparable = dataRow?.[component.context];
  }
};

export const getCardSource = (dataRow, property) => {
  let cardSource;
  Object.keys(dataRow).forEach((key) => {
    const split = key.split('.');
    if (split[1] === property || split[0] === property) {
      cardSource = split[0];
    }
  });
  return cardSource;
};

export const getContextRow = (dataRow, data, component, propertiesChecker) => {
  let layoutIncomponent = false;
  let filteredData = data;

  // get data by context property
  let dataRowComparable =
    dataRow?.[
      `${getCardSource(dataRow, component?.context)}.${component?.context}`
    ];

  // check if dataRow comes from map popup / layout in a component

  if (component?.context && dataRow?.[component?.context]) {
    layoutIncomponent = true;
    dataRowComparable = dataRow?.[component.context];
  }

  const dataRowCube = getDataRowCube(dataRow);

  if (dataRow && component?.context && propertiesChecker) {
    filteredData = data.filter(
      (item) =>
        String(item[`${component?.cube}.${component?.context}`]) ===
        String(
          dataRow[`${dataRowCube}.${component?.context}`] ||
            dataRow[`${component?.context}`]
        )
    );
    return { filteredData, layoutIncomponent };
  }
  return { filteredData, layoutIncomponent };
};

export const handleError = (error, user, email, history, location) => {
  /**
   * 1. logged in users if unauthorized - unauthorized page else alert error
   * 2. non logged in users - if unauthorized redirect to login else alert error
   */
  if (user.authenticated && email) {
    if (error === 'Unauthorized') {
      history.push('/unauthorized');
    } else {
      message.error(error || ERROR_GENERIC);
    }
  } else {
    if (error === 'Unauthorized') {
      if (!window.location.search.includes('?redirect')) {
        history.push(`/user/login?redirect=${location?.pathname}`);
      }
    } else {
      message.error(error || ERROR_GENERIC);
    }
  }
};

export const allMapsLoaded = (maps, post, hash) => {
  // for posts without maps trigger then apply url filters
  const mapExists = post.components?.some((d) => d.type === 'map');
  if (!mapExists && hash && post.uuid) {
    return true;
  }
  return Object.keys(maps).every((mapId) => maps?.[mapId]?.isStyleLoaded());
};
