import React, { useEffect, useState } from 'react';
import { Select, Slider, Tree, Input, DatePicker } from 'antd';
import {
  getFilterMode,
  getGroupedFilter,
  getCheckedKeys,
  getExpandedKeys,
  getSliderValue,
  onFilterChange,
} from './helpers';
import { getLayerFilters } from '../../../helpers';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { OPERATORS } from '../../../../App/Filter';
import { QUERY_API } from '../../../../../configs/env';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { generateJWTToken } from '../../../utils'

const { Option } = Select;
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

/** dayjs support hack https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400 */
dayjs.extend(weekday);
dayjs.extend(localeData);

const MapFilterControl = (props) => {
  const {
    index,
    layer,
    filterIndex,
    data,
    postSources,
    filter,
    actionComponentSourceQuery,
    actionComponentMapLayerFilterEdit,
    primaryLayerIndex,
  } = props;
  const { filters, cube } = layer;

  const property = filters?.[filterIndex]?.[1];
  const operator = filters?.[filterIndex]?.[0];
  const textValue = filters?.[filterIndex]?.[2];
  const dataType = filters ? filters?.[filterIndex]['dataType'] : '';
  const labelledProperty =
    postSources?.[layer.source]?.labels?.[property]?.title || property;
  const firstRangeValue = filters ? filters?.[filterIndex][3] : '';
  const secondRangeValue = filters ? filters?.[filterIndex][4] : '';
  const showActualDateFormat = filters?.[filterIndex]['actualDateFormat'];
  const rangeFirstValue = filters?.[filterIndex]?.[3];
  const rangeSecondValue = filters?.[filterIndex]?.[2];
  const renderText = filters?.[filterIndex]?.['showTextField'];
  const filterObj = filters?.[filterIndex];
  const [sliderValue, setSliderValue] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const [operatorObj, setOperatorObj] = useState();
  const rangeOperators = ['notInDateRange', 'inDateRange'].includes(operator);
  const heading =
    property && operator
      ? `${dataType === 'time' ? '' : labelledProperty} ${operatorObj?.name}`
      : '';
  const itemData = data?.[`${layer.id}-filter-${filterIndex}`]?.length
    ? data?.[`${layer.id}-filter-${filterIndex}`]
    : [];
  const getIndex = itemData?.findIndex(
    (record) => record[`${cube}.${property}`] === filterValue
  );
  const rangeSliderValues = [0, 0];
  const sortedItemData = itemData
    .map((item) => {
      return item[`${layer.cube}.${property}`];
    })
    .sort((a, b) => new Date(a) - new Date(b));
  let minDate;
  let maxDate;
  if (sortedItemData.length) {
    minDate = sortedItemData[0];
    maxDate = sortedItemData[sortedItemData.length - 1];
  }
  itemData?.forEach((item, key) => {
    if (item[`${layer.cube}.${property}`] === filters[filterIndex]?.[3]) {
      rangeSliderValues[0] = key;
    }
    if (item[`${layer.cube}.${property}`] === filters[filterIndex]?.[4]) {
      rangeSliderValues[1] = key;
    }
  });
  const [sliderIndex, setSliderIndex] = useState(cube ? getIndex : sliderValue);
  const [rangeFilterValue, setRangeFilterValue] = useState(rangeSliderValues);
  const dateFormat = 'YYYY/MM/DD';
  const disabledRangePicker = (date) => {
    if (date == null) {
      return false;
    }

    if (!sortedItemData?.length) {
      return false;
    }
    if (minDate && maxDate) {
      return dayjs(minDate).isAfter(date) || dayjs(maxDate).isBefore(date);
    }

    return false;
  };

  useEffect(() => {
    if (rangeOperators) {
      setRangeFilterValue(rangeSliderValues);
    } else {
      setSliderIndex(cube ? getIndex : sliderValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.[`${layer.id}-filter-${filterIndex}`]?.length]);

  useEffect(() => {
    if (Array.isArray(sliderValue)) {
      setRangeFilterValue(sliderValue);
    } else {
      setSliderIndex(sliderValue);
    }
  }, [sliderValue]);

  useEffect(() => {
    if (filterValue) {
      setSliderIndex(cube ? getIndex : sliderValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  useEffect(() => {
    if (rangeOperators) {
      setRangeFilterValue(rangeSliderValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRangeValue, secondRangeValue]);

  useEffect(() => {
    if (
      data &&
      data[`${layer.id}-filter-${filterIndex}`] &&
      postSources?.[layer.source]
    ) {
      const { layer, postSources, filterIndex } = props;
      const value = getSliderValue(layer, postSources, data, filterIndex);
      setSliderValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setFilterValue(textValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue]);

  useEffect(() => {
    if (operator) {
      const operatorObj = OPERATORS.find((op) => op.value === operator);
      if (operatorObj) {
        setOperatorObj(operatorObj);
      }
    }
  }, [operator]);

  useEffect(() => {
    if (property) {
      if (postSources[layer.source] && postSources[layer.source].cube) {
        const source = postSources[layer.source];
        let dimensions = [`${source.cube}.${property}`];
        let cascadeFilters;
        if (layer?.filters?.length > 1 && filterIndex !== 0 && layer?.cascade) {
          const excludeFinalFilters = [...layer?.filters];
          excludeFinalFilters.pop();
          cascadeFilters =
            layer?.filters?.length > 1
              ? getLayerFilters(excludeFinalFilters, { cube: source.cube })
              : [];
        }
        if (filter.groupBy === true && filter.groupByValue) {
          dimensions = [
            `${source.cube}.${property}`,
            `${source.cube}.${filter.groupByValue}`,
          ];
        }
        const componentId = `${layer.id}-filter-${filterIndex}`;
        const query = {
          dimensions: dimensions,
          measures: [],
          limit: 20000,
          filters: cascadeFilters,
        };

        generateJWTToken({
          sourceId: source?.uuid,
          cubeName: source?.cube,
          refreshKey: source?.refresh_key,
        }).then(res => {
          const token = res;
          const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          };
          const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
          service.create({
            query: query,
          })
          .then((res) => {
            actionComponentSourceQuery(res.data, componentId, query);
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, filters, layer?.cascade]);

  if (data[`${layer.id}-filter-${filterIndex}`]) {
    if (layer.filters[filterIndex].groupBy) {
      const options = getGroupedFilter(props);
      return (
        <div className='map-filter-control'>
          <label>{filterObj?.['name'] || heading}</label>
          <Tree
            height={290}
            checkable
            defaultExpandedKeys={getExpandedKeys(props, options)}
            defaultCheckedKeys={getCheckedKeys(props, options)}
            checkedKeys={getCheckedKeys(props, options)}
            onCheck={(keys, info) => {
              const array = info.checkedNodes.map((item) => item.value);
              actionComponentMapLayerFilterEdit({
                componentIndex: index,
                layerIndex: primaryLayerIndex,
                childLayerIndex: layer.childLayerIndex,
                filterIndex: filterIndex,
                propertyIndex: 2,
                value: array,
              });
            }}
            treeData={options}
          />
        </div>
      );
    } else {
      if (rangeOperators) {
        return (
          <div className='map-filter-control'>
            <label>{filterObj?.['name'] || heading}</label>
            {layer.filters[filterIndex].slider && (
              <Slider
                min={0}
                range={{ draggableTrack: true }}
                defaultValue={rangeFilterValue}
                value={rangeFilterValue}
                max={data[`${layer.id}-filter-${filterIndex}`]?.length - 1}
                tipFormatter={(value) => {
                  const toolTipValue =
                    data[`${layer.id}-filter-${filterIndex}`]?.[value][
                      `${postSources[layer.source]?.cube}.${property}`
                    ];
                  return !showActualDateFormat
                    ? toolTipValue?.toString()?.split('T')[0]
                    : toolTipValue;
                }}
                onChange={(value) => {
                  setSliderValue(value);
                }}
                onAfterChange={(value) => {
                  actionComponentMapLayerFilterEdit({
                    componentIndex: index,
                    layerIndex: primaryLayerIndex,
                    childLayerIndex: layer.childLayerIndex,
                    filterIndex: filterIndex,
                    propertyIndex: 3,
                    value:
                      data[`${layer.id}-filter-${filterIndex}`]?.[value[0]][
                        `${postSources[layer.source].cube}.${property}`
                      ],
                  });
                  actionComponentMapLayerFilterEdit({
                    componentIndex: index,
                    layerIndex: primaryLayerIndex,
                    childLayerIndex: layer.childLayerIndex,
                    filterIndex: filterIndex,
                    propertyIndex: 4,
                    value:
                      data[`${layer.id}-filter-${filterIndex}`]?.[value[1]][
                        `${postSources[layer.source].cube}.${property}`
                      ],
                  });
                }}
              />
            )}
            {layer.filters[filterIndex].datePicker && (
              <RangePicker
                value={
                  filterObj?.[3] && filterObj?.[4]
                    ? [
                        dayjs(filterObj?.[3], dateFormat),
                        dayjs(filterObj?.[4], dateFormat),
                      ]
                    : []
                }
                defaultPickerValue={[
                  dayjs(minDate, dateFormat),
                  dayjs(maxDate, dateFormat),
                ]}
                disabledDate={disabledRangePicker}
                onChange={(value, dateString) => {
                  if (dateString[0] || dateString[1]) {
                    actionComponentMapLayerFilterEdit({
                      componentIndex: index,
                      layerIndex: primaryLayerIndex,
                      childLayerIndex: layer.childLayerIndex,
                      filterIndex: filterIndex,
                      propertyIndex: 3,
                      value: `${dateString[0]}T00:00:00.000`,
                    });
                    actionComponentMapLayerFilterEdit({
                      componentIndex: index,
                      layerIndex: primaryLayerIndex,
                      childLayerIndex: layer.childLayerIndex,
                      filterIndex: filterIndex,
                      propertyIndex: 4,
                      value: `${dateString[1]}T00:00:00.000`,
                    });
                  } else {
                    actionComponentMapLayerFilterEdit({
                      componentIndex: index,
                      layerIndex: primaryLayerIndex,
                      childLayerIndex: layer.childLayerIndex,
                      filterIndex: filterIndex,
                      propertyIndex: 3,
                      value: undefined,
                    });
                    actionComponentMapLayerFilterEdit({
                      componentIndex: index,
                      layerIndex: primaryLayerIndex,
                      childLayerIndex: layer.childLayerIndex,
                      filterIndex: filterIndex,
                      propertyIndex: 4,
                      value: undefined,
                    });
                  }
                }}
              />
            )}
            {!layer.filters[filterIndex].datePicker &&
              !layer.filters[filterIndex].slider &&
              !renderText &&
              !['>', '<', '<=', '>=', 'contains', 'isNull', 'notNull'].includes(
                operator
              ) && (
                <>
                  <Select
                    showSearch
                    placeholder='Select...'
                    allowClear
                    mode={getFilterMode(layer.filters[filterIndex])}
                    value={filters[filterIndex]?.[3]}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      onFilterChange(
                        {
                          index: index,
                          primaryLayerIndex: primaryLayerIndex,
                          layer: layer,
                          filterIndex: filterIndex,
                          actionComponentMapLayerFilterEdit:
                            actionComponentMapLayerFilterEdit,
                          propertyIndex: 3,
                        },
                        value
                      );
                    }}
                  >
                    {data &&
                      data[`${layer.id}-filter-${filterIndex}`] &&
                      data[`${layer.id}-filter-${filterIndex}`].map(
                        (item, index) =>
                          item[
                            `${postSources[layer.source].cube}.${property}`
                          ] &&
                          item[
                            `${postSources[layer.source].cube}.${property}`
                          ] !== '0' && (
                            <Option
                              key={index}
                              value={
                                item[
                                  `${
                                    postSources[layer.source].cube
                                  }.${property}`
                                ]
                              }
                            >
                              {!showActualDateFormat
                                ? item[
                                    `${
                                      postSources[layer.source].cube
                                    }.${property}`
                                  ]?.split('T')[0]
                                : item[
                                    `${
                                      postSources[layer.source].cube
                                    }.${property}`
                                  ]}
                            </Option>
                          )
                      )}
                  </Select>
                  <br />
                  <br />
                  <Select
                    showSearch
                    placeholder='Select...'
                    allowClear
                    mode={getFilterMode(layer.filters[filterIndex])}
                    value={filters[filterIndex]?.[4]}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      onFilterChange(
                        {
                          index: index,
                          primaryLayerIndex: primaryLayerIndex,
                          layer: layer,
                          filterIndex: filterIndex,
                          actionComponentMapLayerFilterEdit:
                            actionComponentMapLayerFilterEdit,
                          propertyIndex: 4,
                        },
                        value
                      );
                    }}
                  >
                    {data &&
                      data[`${layer.id}-filter-${filterIndex}`] &&
                      data[`${layer.id}-filter-${filterIndex}`].map(
                        (item, index) =>
                          item[
                            `${postSources[layer.source].cube}.${property}`
                          ] &&
                          item[
                            `${postSources[layer.source].cube}.${property}`
                          ] !== '0' && (
                            <Option
                              key={index}
                              value={
                                item[
                                  `${
                                    postSources[layer.source].cube
                                  }.${property}`
                                ]
                              }
                            >
                              {!showActualDateFormat
                                ? item[
                                    `${
                                      postSources[layer.source].cube
                                    }.${property}`
                                  ]?.split('T')[0]
                                : item[
                                    `${
                                      postSources[layer.source].cube
                                    }.${property}`
                                  ]}
                            </Option>
                          )
                      )}
                  </Select>
                </>
              )}
            {!layer.filters[filterIndex].slider &&
              !layer.filters[filterIndex].datePicker &&
              (renderText ||
                ['>', '<', '<=', '>=', 'contains'].includes(operator)) &&
              !['isNull', 'notNull'].includes(operator) && (
                <>
                  <Input
                    allowClear
                    placeholder='Set value to Filter by'
                    value={
                      !showActualDateFormat
                        ? rangeFirstValue?.split('T')[0]
                        : rangeFirstValue
                    }
                    onChange={(e) => {
                      setFilterValue(e.target.value);
                    }}
                    onBlur={() => {
                      onFilterChange(
                        {
                          index: index,
                          primaryLayerIndex: primaryLayerIndex,
                          layer: layer,
                          filterIndex: filterIndex,
                          actionComponentMapLayerFilterEdit:
                            actionComponentMapLayerFilterEdit,
                        },
                        filterValue,
                        true
                      );
                    }}
                    onPressEnter={() => {
                      onFilterChange(
                        {
                          index: index,
                          primaryLayerIndex: primaryLayerIndex,
                          layer: layer,
                          filterIndex: filterIndex,
                          actionComponentMapLayerFilterEdit:
                            actionComponentMapLayerFilterEdit,
                        },
                        filterValue,
                        true
                      );
                    }}
                  />
                  <br />
                  <br />
                  <Input
                    allowClear
                    placeholder='Set value to Filter by'
                    value={
                      !showActualDateFormat
                        ? rangeSecondValue?.split('T')[0]
                        : rangeSecondValue
                    }
                    onChange={(e) => {
                      setFilterValue(e.target.value);
                    }}
                    onBlur={() => {
                      onFilterChange(
                        {
                          index: index,
                          primaryLayerIndex: primaryLayerIndex,
                          layer: layer,
                          filterIndex: filterIndex,
                          actionComponentMapLayerFilterEdit:
                            actionComponentMapLayerFilterEdit,
                        },
                        filterValue,
                        true
                      );
                    }}
                    onPressEnter={() => {
                      onFilterChange(
                        {
                          index: index,
                          primaryLayerIndex: primaryLayerIndex,
                          layer: layer,
                          filterIndex: filterIndex,
                          actionComponentMapLayerFilterEdit:
                            actionComponentMapLayerFilterEdit,
                        },
                        filterValue,
                        true
                      );
                    }}
                  />
                </>
              )}
          </div>
        );
      }
      return (
        <div className='map-filter-control'>
          <label>{filterObj?.['name'] || heading}</label>
          {layer.filters[filterIndex].slider && (
            <Slider
              min={0}
              value={sliderValue}
              max={data[`${layer.id}-filter-${filterIndex}`].length - 1}
              tipFormatter={(value) => {
                const toolTipValue =
                  data[`${layer.id}-filter-${filterIndex}`]?.[value][
                    `${postSources[layer.source]?.cube}.${property}`
                  ];
                return !showActualDateFormat
                  ? toolTipValue?.toString()?.split('T')[0]
                  : toolTipValue;
              }}
              onChange={(value) => {
                setSliderValue(value);
              }}
              onAfterChange={(value) => {
                actionComponentMapLayerFilterEdit({
                  componentIndex: index,
                  layerIndex: primaryLayerIndex,
                  childLayerIndex: layer.childLayerIndex,
                  filterIndex: filterIndex,
                  propertyIndex: 2,
                  value:
                    data[`${layer.id}-filter-${filterIndex}`]?.[value][
                      `${postSources[layer.source].cube}.${property}`
                    ],
                });
              }}
            />
          )}
          {layer.filters[filterIndex].datePicker && (
            <DatePicker
              disabledDate={disabledRangePicker}
              defaultPickerValue={dayjs(minDate, dateFormat)}
              value={
                filterObj?.[2]?.length
                  ? dayjs(filterObj?.[2], dateFormat)
                  : undefined
              }
              onChange={(value, dateString) => {
                if (
                  layer.cascade &&
                  layer?.filters?.length > 1 &&
                  filterIndex !== undefined &&
                  !value
                ) {
                  filters.forEach((filterItem, key) => {
                    if (filterIndex < key) {
                      onFilterChange(
                        {
                          index: index,
                          primaryLayerIndex: primaryLayerIndex,
                          layer: layer,
                          filterIndex: key,
                          actionComponentMapLayerFilterEdit:
                            actionComponentMapLayerFilterEdit,
                        },
                        undefined
                      );
                    }
                  });
                }
                if (!dateString) {
                  onFilterChange(
                    {
                      index: index,
                      primaryLayerIndex: primaryLayerIndex,
                      layer: layer,
                      filterIndex: filterIndex,
                      actionComponentMapLayerFilterEdit:
                        actionComponentMapLayerFilterEdit,
                    },
                    undefined
                  );
                } else {
                  onFilterChange(
                    {
                      index: index,
                      primaryLayerIndex: primaryLayerIndex,
                      layer: layer,
                      filterIndex: filterIndex,
                      actionComponentMapLayerFilterEdit:
                        actionComponentMapLayerFilterEdit,
                    },
                    dateString
                  );
                }
              }}
            />
          )}
          {!layer.filters[filterIndex].datePicker &&
            !layer.filters[filterIndex].slider &&
            !renderText &&
            !['>', '<', '<=', '>=', 'contains', 'isNull', 'notNull'].includes(
              operator
            ) && (
              <Select
                showSearch
                placeholder='Select...'
                allowClear
                mode={getFilterMode(layer.filters[filterIndex])}
                value={filters[filterIndex]?.[2]}
                style={{ width: '100%' }}
                onChange={(value) => {
                  if (
                    layer.cascade &&
                    layer?.filters?.length > 1 &&
                    filterIndex !== undefined &&
                    !value
                  ) {
                    filters.forEach((filterItem, key) => {
                      if (filterIndex < key) {
                        onFilterChange(
                          {
                            index: index,
                            primaryLayerIndex: primaryLayerIndex,
                            layer: layer,
                            filterIndex: key,
                            actionComponentMapLayerFilterEdit:
                              actionComponentMapLayerFilterEdit,
                          },
                          value
                        );
                      }
                    });
                  }
                  onFilterChange(
                    {
                      index: index,
                      primaryLayerIndex: primaryLayerIndex,
                      layer: layer,
                      filterIndex: filterIndex,
                      actionComponentMapLayerFilterEdit:
                        actionComponentMapLayerFilterEdit,
                    },
                    value
                  );
                }}
              >
                {data &&
                  data[`${layer.id}-filter-${filterIndex}`] &&
                  data[`${layer.id}-filter-${filterIndex}`].map(
                    (item, index) => {
                      const option =
                        item[`${postSources[layer.source].cube}.${property}`];
                      return (
                        option &&
                        option !== '0' && (
                          <Option
                            key={index}
                            value={
                              item[
                                `${postSources[layer.source].cube}.${property}`
                              ]
                            }
                          >
                            {!showActualDateFormat && typeof option !== 'string'
                              ? String(option)?.split('T')?.[0]
                              : item[
                                  `${
                                    postSources[layer.source].cube
                                  }.${property}`
                                ]}
                          </Option>
                        )
                      );
                    }
                  )}
              </Select>
            )}
          {!layer.filters[filterIndex].slider &&
            (renderText ||
              ['>', '<', '<=', '>=', 'contains'].includes(operator)) &&
            !['isNull', 'notNull'].includes(operator) && (
              <>
                <Input
                  allowClear
                  placeholder='Set value to Filter by'
                  value={
                    !showActualDateFormat
                      ? String(filterValue)?.split('T')?.[0]
                      : String(filterValue) || undefined
                  }
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                  }}
                  onBlur={() => {
                    onFilterChange(
                      {
                        index: index,
                        primaryLayerIndex: primaryLayerIndex,
                        layer: layer,
                        filterIndex: filterIndex,
                        actionComponentMapLayerFilterEdit:
                          actionComponentMapLayerFilterEdit,
                      },
                      filterValue,
                      true
                    );
                  }}
                  onPressEnter={() => {
                    onFilterChange(
                      {
                        index: index,
                        primaryLayerIndex: primaryLayerIndex,
                        layer: layer,
                        filterIndex: filterIndex,
                        actionComponentMapLayerFilterEdit:
                          actionComponentMapLayerFilterEdit,
                      },
                      filterValue,
                      true
                    );
                  }}
                />
              </>
            )}
        </div>
      );
    }
  } else {
    return null;
  }
};

export default MapFilterControl;
