import { Dictionary } from '@onaio/utils';

export const excludePreSelectedOptions = (
  data: Dictionary[],
  groupByColors: Dictionary[],
  groupByValue: string
): Dictionary[] => {
  const options: Dictionary[] = [];
  if (data?.length > 0) {
    data.forEach((item: Dictionary) => {
      const color = groupByColors?.find((color: Dictionary) => color.value === item[groupByValue]);
      if (!color) {
        options.push(item);
      }
    });
  }
  return options;
};
