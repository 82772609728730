import { actionPostComponentSettingEdit } from '../../../../actions';
import chroma from 'chroma-js';
import { Dictionary } from '@onaio/utils';
import { makeId } from '../../ArrayInput/utils/helpers';
import { Action } from 'redux';
import { scaleQuantile, scaleQuantize } from 'd3-scale';
import scaleCluster from 'd3-scale-cluster';
import { componentSettingsProps } from '../../TextInput/helpers/helpers';
import { ComponentProps } from '../../../../Map';
import { GenericComponentProps } from '../../../../Component';
export interface ConfigsProps {
  componentIndex: number;
  itemIndex: number | undefined;
  parents: string[];
  layerId: string;
  colorField: string;
  cube: string;
  colorRange: string;
  colorScale: string;
  classes: number;
  componentId?: string;
  componentCube?: string;
  reverseColors?: boolean;
}

export type colorFieldPropData = string | number;

/**
 * Computes Jenks natural breaks matrices for given data.
 * @param data Array of numeric data points.
 * @param numBreaks Number of breaks (classes) to compute.
 * @returns Object containing lower class limits and variance combinations matrices.
 */
export const jenksMatrices = (data: number[], numBreaks: number) => {
  // Initialize matrices for lower class limits and variance combinations
  const lowerClassLimits: number[][] = Array.from(
    { length: data.length + 1 },
    () => Array(numBreaks + 1).fill(0)
  );
  const varianceCombinations: number[][] = Array.from(
    { length: data.length + 1 },
    () => Array(numBreaks + 1).fill(Infinity)
  );

  // Initialize first row of matrices
  for (let i = 1; i <= numBreaks; i++) {
    lowerClassLimits[1][i] = 1;
    varianceCombinations[1][i] = 0;
  }

  // Compute matrices for each row l from 2 to data.length
  for (let l = 2; l <= data.length; l++) {
    let sum = 0;
    let sumSquares = 0;
    let w = 0;

    // Compute sums and squares for current row
    for (let m = 1; m <= l; m++) {
      const i3 = l - m + 1;
      const val = data[i3 - 1];
      w++;
      sum += val;
      sumSquares += val * val;
      let variance = sumSquares - (sum * sum) / w;
      const i4 = i3 - 1;

      // Update variance combinations
      if (i4 !== 0) {
        for (let j = 2; j <= numBreaks; j++) {
          if (
            varianceCombinations[l][j] >=
            variance + varianceCombinations[i4][j - 1]
          ) {
            lowerClassLimits[l][j] = i3;
            varianceCombinations[l][j] =
              variance + varianceCombinations[i4][j - 1];
          }
        }
      }
    }

    // Set values for the first column of each row
    lowerClassLimits[l][1] = 1;
    varianceCombinations[l][1] = sumSquares - (sum * sum) / w;
  }

  // Return matrices
  return {
    lowerClassLimits,
    varianceCombinations,
  };
};

export const jenks = (data: number[], numBreaks: number): number[] => {
  data.sort((a, b) => a - b);

  const { lowerClassLimits } = jenksMatrices(data, numBreaks);

  const kclass: number[] = new Array(numBreaks + 1).fill(0);
  let k = data.length - 1;
  let countNum = numBreaks;

  kclass[numBreaks] = data[data.length - 1];

  while (countNum > 0) {
    kclass[countNum - 1] = data[lowerClassLimits[k][countNum] - 1];
    k = lowerClassLimits[k][countNum] - 1;
    countNum--;
  }

  return kclass;
};

// linear grouping  utility

export const quantizeEqualIntervals = (
  arr: number[],
  chunkSize: number
): number[] => {
  const scale = scaleQuantile()
    .domain(arr)
    .range([...Array(chunkSize || 6).keys()]);
  const lastScaleInvert = scale.invertExtent(chunkSize - 1 || 6)[0];
  const breaks = scaleQuantize()
    .domain([arr[0], arr[arr.length - 1]])
    .range([...Array(chunkSize || 6).keys()])
    .thresholds();

  /** push the last element to cover for the n-1 length of range 
        https://d3js.org/d3-scale/quantize#quantize_thresholds **/

  if (breaks[0] > scale.invertExtent(0)[0]) {
    breaks.unshift(scale.invertExtent(0)[0]);
  }
  if (lastScaleInvert < arr[arr.length - 1]) {
    breaks.push(arr[arr.length - 1]);
  }

  return breaks;
};

export const quantileGrouping = (
  arr: number[],
  chunkSize: number
): number[] => {
  /**
   * push the last element to cover for the n-1 length of range
   * https://d3js.org/d3-scale/quantile#quantile_quantiles
   */
  const scale = scaleQuantile()
    .domain(arr)
    .range([...Array(chunkSize || 6).keys()]);
  const lastScaleInvert = scale.invertExtent(chunkSize - 1 || 6)[0];
  const breaks = scaleQuantile()
    .domain(arr)
    .range([...Array(chunkSize || 6).keys()])
    .quantiles();

  if (breaks[0] > scale.invertExtent(0)[0]) {
    breaks.unshift(scale.invertExtent(0)[0]);
  }
  if (lastScaleInvert < arr[arr.length - 1]) {
    breaks.push(arr[arr.length - 1]);
  }

  return breaks;
};

// jenks natural breaks / ckmeans
export const clusterGrouping = (arr: number[], chunkSize: number): number[] => {
  const uniqueList = [...new Set(arr)];
  const classSize =
    uniqueList?.length < chunkSize ? uniqueList?.length : chunkSize;

  const breaks = jenks(uniqueList, classSize || 6);

  return breaks;
};

export const buildCategories = (
  breakIntervals: colorFieldPropData[],
  configs: ConfigsProps,
  dispatch: (action: Action) => void,
  property: string,
  generateColors?: Dictionary[]
): void => {
  const categories: Dictionary[] = [];
  const {
    colorRange,
    componentIndex,
    parents,
    itemIndex,
    classes,
    reverseColors,
  } = configs;
  let palette: string[] = [];
  if (!generateColors?.length) {
    palette = chroma
      .scale(colorRange || 'Oranges')
      .padding(0.1)
      .colors(10);
  } else {
    palette = (generateColors as []).map((item: Dictionary) => item.color);
  }
  palette = reverseColors ? palette.reverse() : palette;
  [...new Set(breakIntervals)].forEach(
    (element: string | number, index: number) => {
      if (index < (classes || 10)) {
        const colorIndex = index % 10;
        categories.push({
          id: makeId(),
          color: palette[colorIndex],
          value: element,
        });
      }
    }
  );
  dispatch(
    actionPostComponentSettingEdit({
      componentIndex: componentIndex,
      itemIndex: itemIndex,
      parents: parents,
      property: property,
      value: categories,
    })
  );
};

export const buildStopsIntervals = (
  breakIntervals: number[],
  configs: ConfigsProps,
  dispatch: (action: Action) => void,
  property: string,
  colorUpdate?: boolean,
  generateColors?: Dictionary[]
): void => {
  const breaks: Dictionary[] = [];
  const {
    colorRange,
    componentIndex,
    classes,
    parents,
    itemIndex,
    reverseColors,
  } = configs;
  const derivedClasses =
    classes === undefined || breakIntervals?.length > classes
      ? breakIntervals.length
      : classes;
  let palette: string[] = [];

  if (
    !generateColors?.length ||
    breakIntervals?.length !== generateColors?.length
  ) {
    palette = chroma
      .scale(colorRange || 'Oranges')
      .padding(0.1)
      .colors(derivedClasses || 6);
  } else {
    palette = (generateColors as []).map((item: Dictionary) => item.color);
  }
  palette = reverseColors ? palette.reverse() : palette;
  [...new Set(breakIntervals)].forEach(
    (element: string | number, index: number) => {
      breaks.push({
        id: makeId(),
        color: palette[index],
        value: colorUpdate ? element : (Number(element) + 0.1).toFixed(1),
      });
    }
  );
  dispatch(
    actionPostComponentSettingEdit({
      componentIndex: componentIndex,
      itemIndex: itemIndex,
      parents: parents,
      property: property,
      value: breaks,
    })
  );
};

export const generateBreaks = (
  post: Dictionary,
  configs: ConfigsProps,
  dispatch: (action: Action) => void,
  componentLevel?: boolean,
  colorBreaks?: string,
  generateColors?: Dictionary[]
): void => {
  const {
    layerId,
    colorField,
    cube,
    colorScale,
    classes,
    componentId,
    componentCube,
  } = configs;
  const layerData = componentLevel
    ? post.data?.[`${componentId}`]
    : post.data?.[`layer-${layerId}`];
  const dataCube = componentLevel ? componentCube : cube;
  if (layerData?.length > 0 && colorField) {
    let colorFieldData: colorFieldPropData[] = layerData.map(
      (data: Dictionary) => data[`${dataCube}.${colorField}`]
    );
    colorFieldData = colorFieldData
      .filter(
        (record: colorFieldPropData) =>
          !(record === null || record === undefined)
      )
      .sort(
        (firstVal: colorFieldPropData, lastVal: colorFieldPropData) =>
          Number(firstVal) - Number(lastVal)
      );
    let breakIntervals: number[];
    if (colorFieldData.length > 0) {
      const parseNumbers = colorFieldData.map((field) => Number(field));
      if (colorScale === 'linear') {
        breakIntervals =
          colorFieldData.length >= (classes || 6)
            ? quantizeEqualIntervals(parseNumbers, classes || 6)
            : quantizeEqualIntervals(parseNumbers, parseNumbers.length);
      } else if (colorScale === 'quantile') {
        breakIntervals =
          parseNumbers.length >= (classes || 6)
            ? quantileGrouping(parseNumbers, classes || 6)
            : quantileGrouping(parseNumbers, parseNumbers.length);
      } else {
        breakIntervals =
          colorFieldData.length >= (classes || 6)
            ? clusterGrouping(parseNumbers, classes || 6)
            : clusterGrouping(parseNumbers, colorFieldData.length);
      }
      buildStopsIntervals(
        breakIntervals,
        configs,
        dispatch,
        colorBreaks || 'colorBreaks',
        true,
        generateColors
      );
    }
  }
};

export const generateCategories = (
  post: Dictionary,
  configs: ConfigsProps,
  dispatch: (action: Action) => void,
  componentLevel?: boolean,
  colorBreaks?: string,
  generateColors?: Dictionary[]
): void => {
  const { layerId, colorField, cube, componentId, componentCube } = configs;
  const layerData = componentLevel
    ? post.data?.[`${componentId}`]
    : post.data?.[`layer-${layerId}`];
  const dataCube = componentLevel ? componentCube : cube;

  if (layerData?.length > 0 && colorField) {
    const colorFieldData: colorFieldPropData[] = layerData.map(
      (data: Dictionary) => data[`${dataCube}.${colorField}`]
    );
    buildCategories(
      colorFieldData,
      configs,
      dispatch,
      colorBreaks || 'colorCategories',
      generateColors
    );
  }
};

export const shouldGenerateColors = (
  prevColorScale: string | undefined,
  layer: Dictionary,
  colorBreaksField: string
): Dictionary[] => {
  if (
    prevColorScale !== layer.colorScale
    // &&
    // layer?.[colorBreaksField]?.length >= layer?.classes
  ) {
    return layer[colorBreaksField];
  } else if (
    layer?.[colorBreaksField]?.length &&
    layer?.['colorRange'] === undefined
  ) {
    return layer[colorBreaksField];
  }
  return [];
};

export const getLayerData = (
  post: Dictionary,
  layer: Dictionary,
  firstParentValues: Dictionary
): string | number =>
  post.data?.[`layer-${layer?.id}`]?.[0]?.[
    `${firstParentValues?.cube}.${firstParentValues?.colorField}`
  ];

export const getComponentData = (
  post: Dictionary,
  component: Dictionary,
  firstParentValues: Dictionary
): string | number =>
  post.data?.[component?.id]?.[0]?.[
    `${component?.cube}.${firstParentValues?.value}`
  ];

export const getProperty = (colorMethod: string) => {
  const propertyMap: Dictionary<string> = {
    breaks: 'colorBreaks',
    generatedStepsBrakes: 'ungroupedData',
    categories: 'colorCategories',
    categorical: 'colorCategories',
  };

  return propertyMap[colorMethod] || 'colorBreaks';
};

export const colorPropGetter = (
  componentItem: Dictionary,
  component: Dictionary
) => {
  const { type } = component;
  const { colorMode, colorMethod, generatedSteps, colorRange } = componentItem;

  if (
    type === 'table' &&
    colorMode === 'generatedStepsBrakes' &&
    colorRange === undefined
  ) {
    return generatedSteps;
  }

  const property = getProperty(
    type === 'chart' || type === 'table' ? colorMode : colorMethod
  );
  return componentItem[property];
};
