import React, { useState } from 'react';
import { LogoutOutlined, DatabaseOutlined, ProfileOutlined, FileOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { AkukoAPIService } from '../../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../../configs/env';

const UserMenu = (props) => {
  const params = useParams();
  return (
    <div className="account-settings-menu">
      <div className="menu-name">
        <h2>{props.user.handle}</h2>
      </div>
      <Link to={`/dashboard/posts`}>
        <FileOutlined /> Posts
      </Link>
      <Link to={`/dashboard/sources`}>
        <DatabaseOutlined /> Sources
      </Link>
      <Link to={`/dashboard/settings`}>
        <UserOutlined /> My account
      </Link>
      <a
        href="#logout"
        onClick={(e) => {
          e.preventDefault();
          const service = new AkukoAPIService(IDENTITY_API, 'user/auth');

          service.delete().then(() => {
            props.actionUserLogout();
          });
        }}
      >
        <LogoutOutlined /> Sign out
      </a>
    </div>
  );
};

export default UserMenu;
