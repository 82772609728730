import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ENV } from '../../../configs/env';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user);
  const email = localStorage.getItem('email');
  if (user.authenticated && email) {
    return <Route {...rest} />;
  } else {
    if (!window.location.search.includes('?redirect')) {
      return <Redirect to="/user/login" />;
    }
  }
};

export default PrivateRoute;
