import React from 'react';
import { Input, Form, Button, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { IDENTITY_API } from '../../../configs/env';
import { AkukoAPIService } from '../../../services/serviceClass';
import Footer from '../../Post/Footer';
import './style.css';
import { ERROR_GENERIC } from '../../../configs/constants';

const SetPassword: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="form-set-password">
      <img
        className="form-set-password--brand"
        src="https://assets.akuko.io/akuko-color-logo.png"
      />
      <h1>Create new Password</h1>
      <p>Please create a new password for your Akuko account.</p>
      <Form
        layout="vertical"
        onFinish={async (values) => {
          if (values.password !== values['confirm password']) {
            message.error('Passwords do not match');
          } else {
            const service = new AkukoAPIService(IDENTITY_API, 'user/change-password');
            await service
              .create({
                email: localStorage.getItem('email'),
                newPassword: values.password,
              })
              .then(() => {
                message.success('Password updated. Please sign in.');
                history.push(`/user/login${location.search}`);
              })
              .catch((err) => {
                message.error(err.message || ERROR_GENERIC);
              });
          }
        }}
      >
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm password"
          label="Confirm Password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button htmlType="submit" size="large" type="primary">
          Set password
        </Button>
      </Form>
      <Footer />
    </div>
  );
};

export { SetPassword };
