import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Space,
  Table,
  Tabs,
  Popconfirm,
  Breadcrumb,
  Tag,
  Button,
  message,
} from 'antd';
import {
  DatabaseOutlined,
  SearchOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { getTags } from '../../helpers.js';
import { actionAccountSourcesGet } from '../../../actions/index.js';
import { actionSourceDelete } from '../../../../Source/actions/index.js';
import { ENV, IDENTITY_API, SOURCES_API } from '../../../../../configs/env';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import { genericWriteAccessHandler } from '../../../../Profile/components/ProfileMenu/helpers';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
const { TabPane } = Tabs;

const UserSources = (props) => {
  const space = useSelector((store) => store.space);
  const sources = useSelector((store) => store.sources);
  const user = useSelector((store) => store.user);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [sourceToDeleteUuid, setSourceToDeleteUuid] = useState(null);
  const [publicSources, setPublicSources] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [publicFilters, setPublicFilters] = useState([]);
  const [searchColumn, setSearchColumn] = useState();
  const [searchText, setSearchText] = useState();
  let searchInput;
  let accounts;

  useEffect(() => {
    setShowLoader(true);

    const service = new AkukoAPIService(SOURCES_API, `source/account/${user.handle}`);
    service.list().then((sources) => {
      dispatch(actionAccountSourcesGet(sources));
    });

    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.handle, sourceToDeleteUuid]);

  useEffect(() => {
    setShowLoader(true);

    const service = new AkukoAPIService(SOURCES_API, `source/public`);
    service.list().then((sources) => {
      setPublicSources(sources);
    });

    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.handle]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn('title');
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const tableSource = sources.items.map((item) => {
    return {
      ...item,
      description: item?.config?.description,
    };
  });

  const tablePublicSources = publicSources.map((item) => {
    return {
      ...item,
      description: item?.config?.description,
    };
  });

  return (
    <div className="dashboard">
      <div className="spaces">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>{user.handle}</Breadcrumb.Item>
            <Breadcrumb.Item>sources</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1>Sources</h1>
        <div className="space-header">
          <Row gutter={20}>
            <Col xs={24}>
              <Button
                id="btn-new-source"
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  const service = new AkukoAPIService(SOURCES_API, 'source');
                  service
                    .create({
                      name: 'My great source',
                      account_id: user.handle,
                      created_by: user.email,
                      cube: 'MyGreatSource',
                      config: {},
                      data: [],
                    })
                    .then((res) => {
                      mixpanel.track('Create Source', { source: res.uuid });
                      history.push(`/source/${res.uuid}`);
                    })
                    .catch((error) => {
                      message.error(error.message || ERROR_GENERIC);
                    });
                }}
              >
                New Source
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={24} md={24} xl={24}>
            <Tabs>
              <TabPane tab="My Sources" key={1}>
                <div className="table-wrapper">
                  <Table
                    pagination={{
                      pageSize: 20,
                      position: ['top', 'bottom'],
                      showSizeChanger: false,
                    }}
                    loading={showLoader}
                    columns={[
                      {
                        title: '',
                        width: '10px',
                        render: () => {
                          return (
                            <>
                              <DatabaseOutlined style={{ fontSize: '24px' }} />
                            </>
                          );
                        },
                      },
                      {
                        title: 'Title',
                        dataIndex: 'name',
                        filterDropdown: ({
                          setSelectedKeys,
                          selectedKeys,
                          confirm,
                          clearFilters,
                        }) => (
                          <div style={{ padding: 8 }}>
                            <Input
                              ref={(node) => {
                                searchInput = node;
                              }}
                              placeholder={`Search title`}
                              value={selectedKeys[0]}
                              onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                              }
                              onPressEnter={() => handleSearch(selectedKeys, confirm, 'name')}
                              style={{ marginBottom: 8, display: 'block' }}
                            />
                            <Space>
                              <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, 'name')}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Search
                              </Button>
                              <Button
                                type="link"
                                onClick={() => clearFilters && handleReset(clearFilters)}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Reset
                              </Button>
                            </Space>
                          </div>
                        ),
                        onFilter: (value, record) =>
                          record['name']
                            ? record['name'].toString().toLowerCase().includes(value.toLowerCase())
                            : '',
                        key: 'name',
                        sorter: (a, b) => a.name?.localeCompare(b.name),
                        render: (text, record) => {
                          return <Link to={`/source/${record.uuid}`}>{text}</Link>;
                        },
                      },
                      {
                        title: 'Description',
                        dataIndex: 'description',
                        filterDropdown: ({
                          setSelectedKeys,
                          selectedKeys,
                          confirm,
                          clearFilters,
                        }) => (
                          <div style={{ padding: 8 }}>
                            <Input
                              ref={(node) => {
                                searchInput = node;
                              }}
                              placeholder={`Search title`}
                              value={selectedKeys[0]}
                              onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                              }
                              onPressEnter={() =>
                                handleSearch(selectedKeys, confirm, 'description')
                              }
                              style={{ marginBottom: 8, display: 'block' }}
                            />
                            <Space>
                              <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, 'description')}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Search
                              </Button>
                              <Button
                                type="link"
                                onClick={() => clearFilters && handleReset(clearFilters)}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Reset
                              </Button>
                            </Space>
                          </div>
                        ),
                        onFilter: (value, record) =>
                          record['description']
                            ? record['description']
                                .toString()
                                .toLowerCase()
                                .includes(value.toLowerCase())
                            : '',
                        key: 'description',
                        sorter: (a, b) => a.description?.localeCompare(b.description),
                        render: (text, record) => {
                          return <Link to={`/source/${record.uuid}`}>{text}</Link>;
                        },
                      },
                      {
                        title: 'Type',
                        dataIndex: 'type',
                        filters: [
                          { text: 'CSV', value: 'csv' },
                          { text: 'Parquet', value: 'parquet' },
                          { text: 'GeoJSON', value: 'geojson' },
                          { text: 'GeoParquet', value: 'geoparquet' },
                          { text: 'Google Sheet', value: 'google-sheet' },
                          { text: 'PostgreSQL', value: 'postgres' },
                          { text: 'ClickHouse', value: 'clickhouse' },
                          { text: 'MySQL', value: 'mysql' },
                          { text: 'Ona Data', value: 'onadata' },
                        ],
                        onFilter: (value, record) => {
                          if (record.type) {
                            return record.type.includes(value);
                          } else {
                            return null;
                          }
                        },
                        key: 'type',
                        sorter: (a, b) => a.type?.length - b.type?.length,
                        render: (text) => {
                          return <Tag color="blue">{text}</Tag>;
                        },
                      },
                      {
                        title: 'Updated',
                        dataIndex: 'updated',
                        defaultSortOrder: 'descend',
                        sorter: (a, b) => moment(a.updated) - moment(b.updated),
                        key: 'updated',
                        render: (text, record) => {
                          return (
                            <span className="updated">
                              {moment(text).format('MMM DD YYYY - HH:mm')}
                            </span>
                          );
                        },
                      },
                      {
                        title: '',
                        dataIndex: 'delete',
                        key: 'delete',
                        render: (text, record) => {
                          return (
                            <div style={{ textAlign: 'right' }}>
                              <Popconfirm
                                title="Are you sure you want to delete this source?"
                                onConfirm={() => {
                                  setSourceToDeleteUuid(record.uuid);
                                  setShowDeleteSpinner(true);
                                  const service = new AkukoAPIService(
                                    SOURCES_API,
                                    `source/${record.uuid}`
                                  );
                                  service
                                    .delete()
                                    .then(() => {
                                      dispatch(
                                        actionSourceDelete({
                                          uuid: record.uuid,
                                        })
                                      );
                                      message.success('Source deleted');
                                      setSourceToDeleteUuid(null);
                                      setShowDeleteSpinner(false);
                                    })
                                    .catch((err) => {
                                      if (err.message) {
                                        message.error(err.message);
                                      } else {
                                        message.error(ERROR_GENERIC);
                                      }
                                      setSourceToDeleteUuid(null);
                                      setShowDeleteSpinner(false);
                                    });
                                }}
                              >
                                {showDeleteSpinner && sourceToDeleteUuid === record.uuid ? (
                                  <LoadingOutlined />
                                ) : (
                                  <DeleteOutlined />
                                )}
                              </Popconfirm>
                            </div>
                          );
                        },
                      },
                    ]}
                    dataSource={tableSource}
                  />
                </div>
              </TabPane>
              <TabPane tab="Public Sources" key={2}>
                <div className="table-wrapper">
                  <Table
                    pagination={{
                      pageSize: 20,
                      position: ['top', 'bottom'],
                      showSizeChanger: false,
                    }}
                    loading={showLoader}
                    columns={[
                      {
                        title: '',
                        width: '10px',
                        render: () => {
                          return (
                            <>
                              <DatabaseOutlined style={{ fontSize: '24px' }} />
                            </>
                          );
                        },
                      },
                      {
                        title: 'Title',
                        dataIndex: 'name',
                        filterDropdown: ({
                          setSelectedKeys,
                          selectedKeys,
                          confirm,
                          clearFilters,
                        }) => (
                          <div style={{ padding: 8 }}>
                            <Input
                              ref={(node) => {
                                searchInput = node;
                              }}
                              placeholder={`Search title`}
                              value={selectedKeys[0]}
                              onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                              }
                              onPressEnter={() => handleSearch(selectedKeys, confirm, 'name')}
                              style={{ marginBottom: 8, display: 'block' }}
                            />
                            <Space>
                              <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, 'name')}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Search
                              </Button>
                              <Button
                                type="link"
                                onClick={() => clearFilters && handleReset(clearFilters)}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Reset
                              </Button>
                            </Space>
                          </div>
                        ),
                        onFilter: (value, record) =>
                          record['name']
                            ? record['name'].toString().toLowerCase().includes(value.toLowerCase())
                            : '',
                        key: 'name',
                        sorter: (a, b) => a.name?.localeCompare(b.name),
                        render: (text, record) => {
                          return <Link to={`/source/${record.uuid}`}>{text}</Link>;
                        },
                      },
                      {
                        title: 'Description',
                        dataIndex: 'description',
                        filterDropdown: ({
                          setSelectedKeys,
                          selectedKeys,
                          confirm,
                          clearFilters,
                        }) => (
                          <div style={{ padding: 8 }}>
                            <Input
                              ref={(node) => {
                                searchInput = node;
                              }}
                              placeholder={`Search description`}
                              value={selectedKeys[0]}
                              onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                              }
                              onPressEnter={() =>
                                handleSearch(selectedKeys, confirm, 'description')
                              }
                              style={{ marginBottom: 8, display: 'block' }}
                            />
                            <Space>
                              <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, 'description')}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Search
                              </Button>
                              <Button
                                type="link"
                                onClick={() => clearFilters && handleReset(clearFilters)}
                                size="small"
                                style={{ width: 90 }}
                              >
                                Reset
                              </Button>
                            </Space>
                          </div>
                        ),
                        onFilter: (value, record) =>
                          record['name']
                            ? record['name'].toString().toLowerCase().includes(value.toLowerCase())
                            : '',
                        key: 'name',
                        sorter: (a, b) => a.name?.localeCompare(b.name),
                        render: (text, record) => {
                          return <Link to={`/source/${record.uuid}`}>{text}</Link>;
                        },
                      },
                      {
                        title: 'Type',
                        dataIndex: 'type',
                        filters: [
                          { text: 'CSV', value: 'csv' },
                          { text: 'Parquet', value: 'parquet' },
                          { text: 'GeoJSON', value: 'geojson' },
                          { text: 'GeoParquet', value: 'geoparquet' },
                          { text: 'Google Sheet', value: 'google-sheet' },
                          { text: 'PostgreSQL', value: 'postgres' },
                          { text: 'ClickHouse', value: 'clickhouse' },
                          { text: 'MySQL', value: 'mysql' },
                          { text: 'Ona Data', value: 'onadata' },
                        ],
                        onFilter: (value, record) => {
                          if (record.type) {
                            return record.type.includes(value);
                          } else {
                            return null;
                          }
                        },
                        key: 'type',
                        sorter: (a, b) => a.type?.length - b.type?.length,
                        render: (text) => {
                          return <Tag color="blue">{text}</Tag>;
                        },
                      },
                      {
                        title: 'Tags',
                        dataIndex: 'tags',
                        filters: getTags(publicSources),
                        filterMultiple: true,
                        onFilterDropdownVisibleChange: () => {
                          setPublicFilters([]);
                        },
                        onFilter: (value, record) => {
                          if (record.tags) {
                            const arr = publicFilters;
                            if (!arr.includes(value)) {
                              arr.push(value);
                              setPublicFilters(arr);
                            }
                            return publicFilters.every((value) => {
                              return record.tags.includes(value);
                            });
                          } else {
                            return null;
                          }
                        },
                        key: 'tags',
                        render: (text, record) => {
                          if (record.tags && record.tags.length) {
                            return (
                              <>
                                {record.tags &&
                                  record.tags.map((tag) => (
                                    <Tag key={tag} color="pink">
                                      {tag}
                                    </Tag>
                                  ))}
                              </>
                            );
                          } else {
                            return <Tag>None</Tag>;
                          }
                        },
                      },
                    ]}
                    dataSource={tablePublicSources}
                  />
                </div>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserSources;
