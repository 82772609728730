import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Form, Upload, Input, Col, Tooltip, Breadcrumb, message, Button, Drawer } from 'antd';
import { withRouter, Link, useParams, Redirect } from 'react-router-dom';
import { userCanAccessSpace, getSpaceUsers } from '../../helpers.js';
import moment from 'moment';
import SpaceForm from '../SpaceForm/index.js';
import Loader from '../../../../Post/Loader/Loader.js';
import AccountsMenu from '../AccountsMenu/index.js';
import * as imageService from '../../../../Post/Image/services/index.js';
import ImgCrop from 'antd-img-crop';

import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserImageEdit,
  actionUserGet,
} from '../../../actions/index.js';
import '../../style.css';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, UPLOADS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
const { TextArea } = Input;

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserImageEdit,
  actionUserGet,
};

const UserForm = (props) => {
  const [currentFile, setCurrentFile] = useState(undefined);
  const [showLoader, setShowLoader] = useState(false);
  const [update, setUpdate] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [handle, setHandle] = useState();
  const passwordForm = useRef();

  useEffect(() => {
    setShowLoader(true);

    const service = new AkukoAPIService(IDENTITY_API, `space/account/${props.user.handle}`);
    service
      .list()
      .then((spaces) => {
        props.actionAccountSpacesGet(spaces);
      })
      .finally(() => {
        setShowLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.handle]);

  useEffect(() => {
    setImageUrl(props.user.image);
    setHandle(props.user.handle);
  }, [props.user]);

  useEffect(() => {
    const service = new AkukoAPIService(IDENTITY_API, `user`);
    service.read(props.user.email).then((res) => {
      props.actionUserGet(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const uploadButton = (
    <div>
      {props.user.image && (
        <img
          style={{ width: '40px', height: '40px' }}
          src={`https://assets.akuko.io/${imageUrl}`}
        />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB.');
    }
    return isJpgOrPng && isLt2M;
  };

  if (props.user.uuid) {
    return (
      <div className="dashboard">
        <div className="spaces">
          <div className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>{props.user.handle}</Breadcrumb.Item>
              <Breadcrumb.Item>my account</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col sm={16}>
              <h1>My account</h1>
              <Form
                layout="vertical"
                initialValues={{
                  handle: props.user.handle,
                  name: props.user.name,
                  tagline: props.user.tagline,
                }}
                onFinish={(values) => {
                  const user = Object.assign({}, props.user);
                  const handle = props.user.handle;
                  user.handle = values['handle'];
                  user.tagline = values['tagline'];
                  user.name = values['name'];
                  user.spaces = props.user.spaces;

                  const service = new AkukoAPIService(IDENTITY_API, `user/${user.uuid}`);
                  service
                    .update(user)
                    .then(() => {
                      const service = new AkukoAPIService(
                        IDENTITY_API,
                        `user/handle/update/${handle}/${values['handle']}`
                      );
                      return service.update();
                    })
                    .then(() => {
                      message.success('Account updated');
                      setUpdate(moment().format('x'));
                    });
                }}
              >
                <Form.Item
                  label="handle"
                  name="handle"
                  hasFeedback
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: 'Handle is not available',
                      validator: (field, value) => {
                        if (value !== props.user.handle) {
                          const service = new AkukoAPIService(IDENTITY_API, 'user/handle');

                          return service.read(value).then((handle) => {
                            if (handle.uuid) {
                              return Promise.reject();
                            } else {
                              if (value.length > 2) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject();
                              }
                            }
                          });
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="Display Name" name="name">
                  <Input size="large" />
                </Form.Item>
                <Form.Item label="email">
                  <Input disabled size="large" value={props.user.email} />
                </Form.Item>
                <Form.Item
                  label="About"
                  name="tagline"
                  rules={[
                    {
                      max: 200,
                    },
                  ]}
                >
                  <TextArea rows="5" placeholder="A few lines about me..." />
                </Form.Item>
                <Form.Item name="image" label="Profile image">
                  <ImgCrop>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      action={`${UPLOADS_API}images`}
                      onChange={(e) => {
                        if (e.file.status === 'done') {
                          props.actionUserImageEdit({ value: e.file.response?.image_id });
                        }
                      }}
                    >
                      {uploadButton}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Update Profile
                </Button>
              </Form>

              <br />
              <br />

              <h2>Password</h2>
              <Form
                layout="vertical"
                ref={passwordForm}
                onFinish={(values) => {
                  if (values['new_password'].length < 7) {
                    message.error('Password must be a minimum of 8 characters.');
                  } else if (values['new_password'] !== values['confirm_password']) {
                    message.error('Passwords must match.');
                  } else {
                    const service = new AkukoAPIService(IDENTITY_API, 'user/change-password');
                    service
                      .create({
                        email: localStorage.getItem('email'),
                        newPassword: values['new_password'],
                      })
                      .then(() => {
                        passwordForm.current.resetFields();
                        message.success('Password changed successfully.');
                      })
                      .catch((err) => {
                        if (err.message) {
                          message.error(err.message);
                        } else {
                          message.error(ERROR_GENERIC);
                        }
                      });
                  }
                }}
              >
                <Form.Item
                  name="new_password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Update Password
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const UserFormContainer = connect(mapStateToProps, mapDispatchToProps)(UserForm);

export default UserFormContainer;
