import React, { useEffect, useRef, useState } from 'react';
import { Select, Tooltip, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionComponentSourceQuery,
  actionPostComponentSettingEdit,
  actionPostPropertyEdit,
} from '../../../actions';
import { isGlobalFilter } from '../../helpers/helpers';
import { QuestionCircleFilled, LinkOutlined } from '@ant-design/icons';
import {
  generateBreaks,
  generateCategories,
  getComponentData,
  getLayerData,
} from './helpers/helpers';
import { fetchLayerData } from '../../../Map/components/MapInstance/helpers';
const { Option } = Select;

export interface SelectStepsInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

export interface prevValueRefProps {
  value: string;
  layerField: string;
  colorScale: string;
}

const SelectStepsInput: React.FC<SelectStepsInputProps> = (
  props: SelectStepsInputProps
) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const {
    property,
    label,
    options,
    refreshKey,
    parents,
    selectMode,
    placeHolder,
    objectKey,
    tooltip,
    labelLink,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const firstParentValues =
    itemIndex !== undefined &&
    (component.type === 'table' ||
      component.type === 'chart' ||
      component.type === 'map')
      ? component?.[parents[0]]?.[itemIndex]
      : {};
  const [value, setValue] = useState<string | number>('');
  const layer: Dictionary =
    parents?.length === 1 && itemIndex !== undefined
      ? component?.[parents[0]]?.[itemIndex]
      : {};
  const isMounted = useRef<any>(false);
  const prevValue = useRef<any>();
  const prevColorField = useRef();
  const prevFirstParentValue = useRef();
  const prevLayerData = useRef();
  const prevComponentData = useRef();

  useEffect(() => {
    let currentValue;
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
            property
          ]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (!parents) {
      setValue(component[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layer?.colorMethod,
    firstParentValues?.value,
    firstParentValues?.colorMode,
    parents,
    itemIndex,
    childIndex,
    componentIndex,
  ]);
  const source = post.sources?.[layer?.source];

  const keyedLayerData = getLayerData(post, layer, firstParentValues);
  const keyedComponentData = getComponentData(
    post,
    component,
    firstParentValues
  );

  useEffect(() => {
    const layerData = post.data?.[`layer-${layer?.id}`];
    const componentData = post.data?.[component?.id];

    const generateColors = (colorListingKey: string) => {
      return firstParentValues?.[`${colorListingKey}`]?.length &&
        firstParentValues?.['colorRange'] === undefined
        ? firstParentValues?.[`${colorListingKey}`]
        : [];
    };

    const shouldRender =
      value !== prevValue.current ||
      layer?.colorField !== prevColorField.current ||
      firstParentValues?.value !== prevFirstParentValue.current ||
      keyedLayerData !== prevLayerData.current ||
      keyedComponentData !== prevComponentData.current;

    if (layer && value && isMounted?.current === true && shouldRender) {
      if (
        [
          'breaks',
          'generatedStepsBrakes',
          'categorical',
          'categories',
        ].includes(value as string) &&
        (layer.colorField || layer?.value)
      ) {
        const configs = {
          componentIndex,
          itemIndex,
          parents,
          layerId: layer.id,
          componentId: component.id,
          colorField: layer.colorField || firstParentValues?.value,
          cube: source?.cube,
          classes:
            value === 'categorical' || value === 'categories'
              ? layer.categoricalClasses
              : layer.classes || 6,
          colorRange: layer.colorRange,
          colorScale: layer.colorScale,
          componentCube: component.cube,
        };

        if (
          layerData &&
          layerData?.length > 0 &&
          keyedLayerData !== undefined
        ) {
          if (['categorical', 'categories'].includes(value as any)) {
            generateCategories(
              post,
              configs,
              dispatch,
              false,
              'colorCategories',
              generateColors('colorCategories')
            );
          } else {
            generateBreaks(
              post,
              configs,
              dispatch,
              false,
              'colorBreaks',
              generateColors('colorBreaks')
            );
          }
        } else if (
          component.type === 'table' &&
          componentData &&
          componentData.length > 0 &&
          keyedComponentData !== undefined
        ) {
          if (['categorical', 'categories'].includes(value as any)) {
            generateCategories(
              post,
              configs,
              dispatch,
              true,
              'colorCategories',
              generateColors('colorCategories')
            );
          } else {
            generateBreaks(
              post,
              configs,
              dispatch,
              true,
              'generatedSteps',
              generateColors('generatedSteps')
            );
          }
        } else if (
          component.type === 'chart' &&
          value === 'generatedStepsBrakes' &&
          componentData &&
          componentData.length > 0 &&
          keyedComponentData !== undefined
        ) {
          generateBreaks(
            post,
            configs,
            dispatch,
            true,
            'ungroupedData',
            generateColors('ungroupedData')
          );
        } else {
          // get layer data to post.data
          fetchLayerData(
            component,
            layer,
            source,
            actionComponentSourceQuery,
            50000,
            dispatch
          );
        }
      }
    }

    // Update previous values
    prevValue.current = value;
    prevColorField.current = layer?.colorField;
    prevFirstParentValue.current = firstParentValues?.value;
    prevLayerData.current =
      layerData?.[0]?.[`${layer.cube}.${layer.colorField}`];
    prevComponentData.current =
      componentData?.[0]?.[`${component.cube}.${firstParentValues?.value}`];
    isMounted.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    layer?.colorField,
    firstParentValues?.value,
    keyedLayerData,
    keyedComponentData,
  ]);

  return (
    <div className='layout-input'>
      <Row gutter={10}>
        <Col sm={6}>
          <label>
            {label}
            &nbsp;
            {tooltip && (
              <Tooltip title={tooltip}>
                <QuestionCircleFilled />
              </Tooltip>
            )}
            {labelLink && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={labelLink} target='_blank'>
                <LinkOutlined />
              </a>
            )}
          </label>
        </Col>
        <Col sm={18}>
          <Select
            placeholder={placeHolder ? placeHolder : undefined}
            disabled={isGlobalFilter(component, parents, childIndex, itemIndex)}
            value={value ? value : undefined}
            allowClear
            showSearch
            mode={selectMode}
            getPopupContainer={() =>
              document.getElementById('drawer-content') as HTMLElement
            }
            onChange={(value) => {
              setValue(value);
              setTimeout(() => {
                if (item.entity === 'post') {
                  dispatch(
                    actionPostPropertyEdit({
                      property: property,
                      value: value,
                    })
                  );
                } else {
                  dispatch(
                    actionPostComponentSettingEdit({
                      parents: parents,
                      property: property,
                      componentIndex: componentIndex,
                      itemIndex: itemIndex,
                      childIndex: childIndex,
                      refreshKey: refreshKey,
                      objectKey: objectKey,
                      value: value,
                    })
                  );
                }
              }, 500);
            }}
          >
            {options &&
              options.map((item: Dictionary, index: number) => (
                <Option key={index} value={item?.value}>
                  {item.label}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export { SelectStepsInput };
