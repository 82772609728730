import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Select,
  Input,
  message,
  Form,
  Table,
  Modal,
  Alert,
  Popconfirm,
  Breadcrumb,
  Button,
  Drawer,
} from 'antd';
import { userCanNotChangeRole, generatePassword, userIsAddedToAccount } from '../../../helpers';
import { DeleteOutlined } from '@ant-design/icons';
import { UserPic } from '../../../../Post/UserPic';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../Post/Loader/Loader';
import moment from 'moment';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserAdd,
  actionUserSpaceRoleEdit,
} from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, NOTIFICATIONS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import axios from 'axios';
import { genericWriteAccessHandler } from '../../../../Profile/components/ProfileMenu/helpers';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserSpaceRoleEdit,
  actionUserAdd,
};

const SpaceUsers = (props) => {
  const [update, setUpdate] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [newUserMessage, setNewUserMessage] = useState();
  const params = useParams();
  const [loading, setLoading] = useState();
  const userForm = useRef();

  useEffect(() => {
    setLoading(true);
    const service = new AkukoAPIService(IDENTITY_API, 'space');

    service
      .read(params.id)
      .then((space) => {
        props.actionAccountSpaceGet(space);

        const service = new AkukoAPIService(IDENTITY_API, `user/space/${params.id}`);
        return service.list();
      })
      .then((users) => {
        props.actionAccountUsersGet(users);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const columns = [
    {
      title: '',
      width: '10px',
      render: (record) => {
        return <UserPic author={record.email} />;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '80%',
    },
    {
      title: 'Role',
      dataIndex: 'value',
      key: 'role',
      render: (text, result) => {
        return (
          <Select
            onChange={(value) => {
              const spaces = Object.assign([], result.spaces);
              spaces.forEach((item, index) => {
                if (item.id === props.space.id) {
                  const space = Object.assign({}, item);
                  space.role = value;
                  spaces[index] = space;
                }
              });
              const obj = Object.assign({}, result);
              obj.spaces = spaces;

              const service = new AkukoAPIService(IDENTITY_API, `user/${obj.uuid}`);

              service.update({ ...obj, current_space_id: props.space.id }).then(() => {
                return props.actionUserSpaceRoleEdit({
                  userId: obj.uuid,
                  spaceId: props.space.id,
                  value: value,
                });
              });
            }}
            value={result.value.role}
            style={{ width: '120px' }}
            disabled={userCanNotChangeRole(props)}
          >
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="editor">Editor</Select.Option>
            <Select.Option value="viewer">Viewer</Select.Option>
          </Select>
        );
      },
    },
  ];

  if (!userCanNotChangeRole(props)) {
    columns.push({
      title: '',
      dataIndex: 'remove',
      key: 'remove',
      render: (text, result) => {
        return (
          <Popconfirm
            placement="left"
            title="Are you sure you want to remove this user?"
            onConfirm={() => {
              const spaces = Object.assign([], result.spaces);
              spaces.forEach((item, index) => {
                if (item.id === props.space.id) {
                  spaces.splice(index, 1);
                }
              });
              const obj = Object.assign({}, result, {
                spaces: spaces,
              });
              const service = new AkukoAPIService(IDENTITY_API, `user/${obj.uuid}`);
              service.update({ ...obj, current_space_id: props.space.id }).then(() => {
                setUpdate(moment().format('x'));
              });
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        );
      },
    });
  }

  if (props.space.id) {
    return (
      <div className="dashboard">
        <div className="spaces">
          <div className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>{props.space.account_id}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>spaces</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/space/${props.space.id}`}>{props.space.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>users</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1>Users</h1>
          {newUserMessage && <Alert message={newUserMessage} type="success" />}
          {showLoader && <Loader />}
          <div className="space-header">
            <Row gutter={20}>
              <Col xs={24}>
                <Button
                  type="primary"
                  hidden={!genericWriteAccessHandler(props.user, props.space?.id)}
                  onClick={(e) => {
                    setShowModal(true);
                  }}
                >
                  Add User
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={24} md={24} xl={24}>
              <div className="table-wrapper">
                <Table
                  loading={loading}
                  pagination={{
                    defaultPageSize: 20,
                  }}
                  columns={columns}
                  dataSource={props.space.users}
                />
              </div>
            </Col>
          </Row>
          <Modal
            visible={showModal}
            footer={null}
            onCancel={() => {
              setShowModal(false);
            }}
          >
            <Form
              layout="vertical"
              ref={userForm}
              onFinish={(values) => {
                const email = values['email'].toLowerCase().trim();
                const isAdded = userIsAddedToAccount(email, props);
                if (isAdded) {
                  message.warning('This user already has access to this account.');
                } else {
                  const service = new AkukoAPIService(IDENTITY_API, 'user');
                  service
                    .read(email)
                    .then((user) => {
                      if (user) {
                        message.success('User added');
                        // add the user to the account
                        const spaces = Object.assign([], user.spaces);
                        spaces.push({ id: props.space.id, role: 'viewer' });
                        user.spaces = spaces;
                        axios.get(
                          `${NOTIFICATIONS_API}message/notify/space/${props.space.id}/${email}`
                        );
                        const service = new AkukoAPIService(IDENTITY_API, `user/${user.uuid}`);
                        service.update({ ...user, current_space_id: props.space.id }).then(() => {
                          setUpdate(moment().format('x'));
                        });

                        setShowModal(false);
                        userForm.current.resetFields();
                      } else {
                        const password = generatePassword();
                        // create the user
                        const split = email.split('@');
                        const handle = split[0];
                        const service = new AkukoAPIService(IDENTITY_API, 'user');
                        const user = {
                          email: email,
                          config: {
                            isNew: true,
                          },
                          spaces: [{ id: props.space.id, role: 'viewer' }],
                          username: 'id',
                          handle: handle,
                          password: password,
                        };
                        service
                          .create({ ...user, current_space_id: props.space.id })
                          .then((user) => {
                            props.actionUserAdd(user);

                            setNewUserMessage(
                              `A user has been created for ${email}. An email has been sent.`
                            );
                            setUpdate(moment().format('x'));
                            userForm.current.resetFields();
                            setShowModal(false);
                          })
                          .catch((error) => {
                            message.error(error.message || ERROR_GENERIC);
                          });
                      }
                    })
                    .catch((error) => {
                      message.error(error.message || ERROR_GENERIC);
                    });
                }
              }}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Add user
              </Button>
            </Form>
          </Modal>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const SpaceUsersContainer = connect(mapStateToProps, mapDispatchToProps)(SpaceUsers);

export default withRouter(SpaceUsersContainer);
