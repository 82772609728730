import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  actionUserLogout,
  actionUserGet,
  actionConfigRoleSet,
  actionIsUserAuth,
} from '../../actions';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../configs/env';
import { message } from 'antd';
import { ERROR_GENERIC } from '../../../../configs/constants';

const mapStateToProps = (state) => {
  if (!state) {
    return null;
  } else {
    return state;
  }
};

const mapDispatchToProps = {
  actionUserLogout,
  actionUserGet,
  actionConfigRoleSet,
  actionIsUserAuth,
};

class CheckToken extends Component {
  componentDidMount() {
    this.getUser();
  }

  getUser() {
    const service = new AkukoAPIService(IDENTITY_API, 'user/auth');

    service
      .list()
      .then((res) => {
        this.props.actionIsUserAuth(res);
        const service = new AkukoAPIService(IDENTITY_API, `user`);
        return service.read(res.email);
      })
      .then((res) => {
        return this.props.actionUserGet(res);
      })
      .then((res) => {
        if (res.data.email) {
          document.body.classList.add('user-auth');
        }
      })
      .catch((error) => {
        message.error(error.message || ERROR_GENERIC);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getUser();
    }
  }

  render() {
    return null;
  }
}

const CheckTokenContainer = connect(mapStateToProps, mapDispatchToProps)(CheckToken);

export default withRouter(CheckTokenContainer);
