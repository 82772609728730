import React, { useState, useEffect } from 'react';
import { Input, Form, Button, message } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { IDENTITY_API } from '../../../configs/env';
import Footer from '../../Post/Footer';
import { AkukoAPIService } from '../../../services/serviceClass';
import './style.css';
import { ERROR_GENERIC } from '../../../configs/constants';

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<Dictionary>();
  const [token, setToken] = useState('');

  useEffect(() => {
    if (params?.token) {
      setToken(params.token);
    }
  }, [params]);

  return (
    <div className="form-reset-password">
      <img
        className="form-set-password--brand"
        src="https://assets.akuko.io/akuko-color-logo.png"
      />
      <h1>Reset Password</h1>
      {!token && (
        <div>
          <p>Enter your Akuko account email to reset your password.</p>
          <Form
            layout="vertical"
            onFinish={async (values) => {
              const service = new AkukoAPIService(IDENTITY_API, 'user/reset-password');
              await service
                .create({
                  email: values.email.toLowerCase(),
                })
                .then(() => {
                  message.warning(
                    "If this email is associated with an account, we'll send a reset link to this address."
                  );
                })
                .catch((err) => {
                  message.error(err.message || ERROR_GENERIC);
                });
            }}
          >
            <Form.Item
              name="email"
              label="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" placeholder="Enter your email" />
            </Form.Item>
            <Button htmlType="submit" size="large" type="primary">
              Reset password
            </Button>
          </Form>
          <div style={{ marginTop: '20px' }}>
            <p>
              <Link to={'/user/login'}>&laquo; Back to sign in</Link>
            </p>
          </div>
        </div>
      )}
      {token && (
        <div>
          <p>Create a new password</p>
          <Form
            layout="vertical"
            onFinish={async (values) => {
              if (values.password !== values['confirm password']) {
                message.error('Passwords do not match');
              } else {
                const service = new AkukoAPIService(IDENTITY_API, `user/reset-password/${token}`);
                await service
                  .update({
                    newPassword: values.password,
                    token: token,
                  })
                  .then(() => {
                    message.success('Password updated. Please sign in.');
                    history.push(`/user/login${location.search}`);
                  })
                  .catch((error) => {
                    message.error(error.message || ERROR_GENERIC);
                  });
              }
            }}
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm password"
              label="Confirm New Password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button htmlType="submit" size="large" type="primary">
              Update password
            </Button>
          </Form>
        </div>
      )}
      <Footer />
    </div>
  );
};

export { ResetPassword };
