import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Tooltip, Upload, Button, message, Breadcrumb, Drawer } from 'antd';
import { UnorderedListOutlined, ProfileFilled, AppstoreOutlined } from '@ant-design/icons';
import * as imageService from '../../../../Post/Image/services';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../Post/Loader/Loader';
import AccountsMenu from '../AccountsMenu';
import UserMenu from '../DashboardMenu/UserMenu';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, UPLOADS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import ImgCrop from 'antd-img-crop';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionPostsGet,
  actionUserLogout,
} from '../../../actions';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionPostsGet,
  actionUserLogout,
};

const AccountForm = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [account, setAccount] = useState();
  const [accountImage, setAccountImage] = useState();
  const [grid, setGrid] = useState(true);
  const params = useParams();

  useEffect(() => {
    const service = new AkukoAPIService(IDENTITY_API, `account`);
    service.read(params.id).then((res) => {
      setAccount(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountImage]);

  const uploadButton = (
    <div>
      {account?.image && (
        <img
          style={{ width: '40px', height: '40px' }}
          src={`https://assets.akuko.io/${account.image}`}
        />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB.');
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <div className="dashboard">
      <div className="spaces">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/account/${params.id}`}>{params.id}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>settings</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1>Account</h1>
        <Row gutter={40}>
          <Col sm={12}>
            {account && account.uuid && (
              <Form
                layout="vertical"
                onFinish={(values) => {
                  const service = new AkukoAPIService(IDENTITY_API, `account/${account.uuid}`);
                  service
                    .update(values)
                    .then(() => {
                      message.success('Account settings updated');
                    })
                    .catch((error) => {
                      message.error(error.message || ERROR_GENERIC);
                    });
                }}
              >
                <Form.Item
                  name="name"
                  label="Account name"
                  initialValue={account?.name}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item name="id" label="Account id" initialValue={params.id}>
                  <Input size="large" disabled />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Primary contact"
                  initialValue={account?.email}
                  rules={[
                    {
                      type: 'email',
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Primary contact email" />
                </Form.Item>
                <Button htmlType="submit" size="large" type="primary">
                  Update Account
                </Button>
              </Form>
            )}
          </Col>
          <Col sm={8}>
            <label>Account Icon</label>
            <ImgCrop>
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                action={`${UPLOADS_API}images`}
                onChange={(e) => {
                  if (e.file.status === 'done') {
                    setAccountImage(e.file.response?.image_id);
                    account.image = e.file.response?.image_id;
                    const service = new AkukoAPIService(IDENTITY_API, `account/${account.uuid}`);
                    service
                      .update(account)
                      .then(() => {
                        setAccount(account);
                        const service = new AkukoAPIService(
                          IDENTITY_API,
                          `user/${props.user.uuid}`
                        );
                        return service.update(props.user);
                      })
                      .then(() => {
                        message.success('Account icon updated');
                      })
                      .catch((error) => {
                        message.error(error.message || ERROR_GENERIC);
                      });
                  }
                }}
              >
                {uploadButton}
              </Upload>
            </ImgCrop>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const AccountFormContainer = connect(mapStateToProps, mapDispatchToProps)(AccountForm);

export default withRouter(AccountFormContainer);
