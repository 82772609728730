import { Dictionary } from '@onaio/utils';
import { toTitleCase } from '../../helpers/utils';

/**
 * @param {} properties // array of properties to check
 * @param {} source // the source object
 */
export const getSourcePropertyType = (properties: string[], source: Dictionary) => {
  const { dimensions, measures } = source;

  const dimensionsArray: string[] = [];
  const measuresArray: string[] = [];

  properties.forEach((item: string, index: number) => {
    const isDimension = dimensions?.filter((dimension: Dictionary) => dimension.value === item);
    const isMeasure = measures?.filter((measure: Dictionary) => measure.name === item);
    if (isDimension?.length > 0) {
      dimensionsArray.push(`${source.cube}.${item}`);
    }
    if (isMeasure?.length > 0) {
      measuresArray.push(`${source.cube}.${item}`);
    }
  });

  return {
    dimensions: dimensionsArray,
    measures: measuresArray,
  };
};

/**
 * Remove gid suffix from Google sheet URL
 *
 * @param {string} url Google sheet to remove suffix
 * @returns {string} Google sheet URL with no gid suffix
 */
export const removeGoogleSheetGIDSuffix = (url: string): string => {
  return url.split('#')[0];
};

/**
 * Convert dimension to title case
 *
 * @param {string} str dimension string
 * @returns {string} converted string to title case with no underscores
 */
export const dimensionToTitleCase = (str: string): string => {
  return toTitleCase(str.replace('_', ' '));
};
