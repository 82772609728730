import React, { useState } from 'react';
import {
  FormatPainterOutlined,
  DatabaseOutlined,
  FileOutlined,
  EditOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { userIsAdminOfSpace } from '../../../helpers';
import { genericWriteAccessHandler } from '../../../../../Profile/components/ProfileMenu/helpers';

const SpaceMenu = (props) => {
  const params = useParams();
  if (props.space && props.space.id) {
    return (
      <div className="account-settings-menu">
        <div className="menu-name">{props.space.name && <h2>{props.space.name}</h2>}</div>
        {props.space.id && props.space.id === params.id && (
          <Link to={`/space/${params.id}/posts`}>
            <FileOutlined /> Posts
          </Link>
        )}
        {props.space.id && props.space.id === params.id && (
          <Link to={`/space/${params.id}/sources`}>
            <DatabaseOutlined /> Sources
          </Link>
        )}
        {props.space.id && props.space.id === params.id && (
          <Link to={`/space/${params.id}/users`}>
            <UserOutlined /> Users
          </Link>
        )}
        {props.space.id &&
          props.space.id === params.id &&
          genericWriteAccessHandler(props.user, props.space?.id) && (
            <Link to={`/space/${params.id}/design`}>
              <FormatPainterOutlined /> Design
            </Link>
          )}
        {props.space.id &&
          props.space.id === params.id &&
          genericWriteAccessHandler(props.user, props.space?.id) && (
            <Link to={`/space/${params.id}/settings`}>
              <SettingOutlined /> Settings
            </Link>
          )}
      </div>
    );
  } else {
    return null;
  }
};

export default SpaceMenu;
