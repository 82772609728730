import React, { useEffect, useState } from "react";
import { Alert, Input, Tooltip, Row, Col } from "antd";
import { Dictionary } from "@onaio/utils/";
import { useDispatch, useSelector } from "react-redux";
import { LinkOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { updateStore } from "./helpers/helpers";
export interface TextInputProps {
  componentIndex: number;
  item: Dictionary;
  childIndex?: number;
  itemIndex?: number;
  renderAsColumn?: boolean;
}

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item, renderAsColumn } = props;
  const {
    property,
    label,
    disabled,
    parents,
    addonBefore,
    placeHolder,
    labelLink,
    tooltip,
    size,
    width,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateStore(
        value,
        setError,
        {
          item: item,
          property: property,
          parents: parents,
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          childIndex: childIndex,
        },
        /* @ts-ignore */
        dispatch
      );
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[
          parents[1]
        ]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }

    if (!isNaN(componentIndex) && !parents) {
      setValue(post.components?.[componentIndex]?.[property]);
    }
    if (item.entity === "post") {
      if (post && post[property]) {
        setValue(post[property]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentIndex, childIndex, itemIndex, parents]);

  return (
    <>
      {renderAsColumn ? (
        <>
          {label && (
            <label>
              {label} &nbsp;
              {tooltip && (
                <Tooltip title={tooltip}>
                  <QuestionCircleFilled />
                </Tooltip>
              )}
              {labelLink && (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={labelLink} target="_blank">
                  <LinkOutlined />
                </a>
              )}
            </label>
          )}
          <Input
            style={
              error
                ? {
                    borderColor: "red",
                  }
                : { width: width ? width : undefined }
            }
            size={size ? size : undefined}
            disabled={disabled ? true : false}
            placeholder={placeHolder ? placeHolder : error}
            value={value}
            addonBefore={addonBefore}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          {error && (
            <>
              <br />
              <br />
              <Alert
                message="Invalid Date Format"
                description={error}
                type="error"
              />{" "}
            </>
          )}
        </>
      ) : (
        <Row gutter={10}>
          <Col sm={6}>
            {label && (
              <label>
                {label} &nbsp;
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <QuestionCircleFilled />
                  </Tooltip>
                )}
                {labelLink && (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a href={labelLink} target="_blank">
                    <LinkOutlined />
                  </a>
                )}
              </label>
            )}
          </Col>
          <Col sm={18}>
            <Input
              style={
                error
                  ? {
                      borderColor: "red",
                    }
                  : { width: width ? width : undefined }
              }
              size={size ? size : undefined}
              disabled={disabled ? true : false}
              placeholder={placeHolder ? placeHolder : error}
              value={value}
              addonBefore={addonBefore}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            {error && (
              <>
                <br />
                <br />
                <Alert
                  message="Invalid Date Format"
                  description={error}
                  type="error"
                />{" "}
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export { TextInput };
