import React, { useEffect, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import './SymbolPicker.css';
import { useState } from 'react';
import { Empty, Input, Row, Tabs } from 'antd';
import { getIcons as getOchaIcons } from './ocha-icons';
import { getIcons as getMakiIcons } from './maki-icons';
import { getIcons as getUnicefIcons } from './unicef-icons';
import { Symbol } from './symbol';
import useDebounce from '../../../../../helpers/use-debounce';

const { TabPane } = Tabs;
const defaultOchaIcons = getOchaIcons();
const defaultMakiIcons = getMakiIcons();
const defaultUnicefIcons = getUnicefIcons();
export interface Icon {
  id: string;
  src: string;
  title: string;
}

/** interface for component props */
export interface SymbolPickerProps {
  onChangeComplete: (icon: Icon) => void;
}

const SymbolPicker: React.FC<SymbolPickerProps> = (props: SymbolPickerProps) => {
  const { onChangeComplete } = props;
  const [searchText, setSearchText] = useState('');
  const [ochaIcons, setOchaIcons] = useState(defaultOchaIcons);
  const [makiIcons, setMakiIcons] = useState(defaultMakiIcons);
  const [activeKey, setActiveKey] = useState('ocha');

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const debouncedSearchText = useDebounce(searchText, 1000);

  useEffect(() => {
    if (activeKey === 'ocha') {
      if (debouncedSearchText) {
        const filtered = defaultOchaIcons.filter((icon) =>
          icon.title.toLowerCase().includes(debouncedSearchText)
        );
        setOchaIcons(filtered);
      } else {
        setOchaIcons(defaultOchaIcons);
      }
    }

    if (activeKey === 'maki') {
      if (debouncedSearchText) {
        const filtered = defaultMakiIcons.filter((icon) =>
          icon.title.toLowerCase().includes(debouncedSearchText)
        );
        setMakiIcons(filtered);
      } else {
        setMakiIcons(defaultMakiIcons);
      }
    }
  }, [activeKey, debouncedSearchText]);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <div className="symbol-picker">
      <Input
        className="symbol-picker--search"
        onChange={handleSearch}
        placeholder="Search symbol"
      />

      <Tabs defaultActiveKey="ocha" onChange={handleTabChange}>
        <TabPane tab="OCHA" key="ocha">
          <Row>
            {ochaIcons.length ? (
              ochaIcons.map((icon, i) => {
                return (
                  <Symbol key={i} icon={icon} onChangeComplete={() => onChangeComplete(icon)} />
                );
              })
            ) : (
              <Empty />
            )}
          </Row>
        </TabPane>
        <TabPane tab="MAKI" key="maki">
          <Row>
            {makiIcons.length ? (
              makiIcons.map((icon, i) => {
                return (
                  <Symbol key={i} icon={icon} onChangeComplete={() => onChangeComplete(icon)} />
                );
              })
            ) : (
              <Empty />
            )}
          </Row>
        </TabPane>
        <TabPane tab="UNICEF" key="unicef">
          <Row>
            {defaultUnicefIcons.length ? (
              defaultUnicefIcons.map((icon, i) => {
                return (
                  <Symbol key={i} icon={icon} onChangeComplete={() => onChangeComplete(icon)} />
                );
              })
            ) : (
              <Empty />
            )}
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

SymbolPicker.propTypes = {
  onChangeComplete: PropTypes.func.isRequired, // function to handle icon click
};

export { SymbolPicker };

export const MemoizedSymbolPicker = React.memo(SymbolPicker);
