import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Input, message } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionUserAuth } from '../../actions';
import Footer from '../../../Post/Footer';
import { StarOutlined, LoadingOutlined } from '@ant-design/icons';
import './Login.css';
import { ENV, IDENTITY_API } from '../../../../configs/env';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { ERROR_GENERIC } from '../../../../configs/constants';
import mixpanel from 'mixpanel-browser';


const mapStateToProps = (state) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionUserAuth,
};

const Login = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.user.authenticated && props.user.email) {
      props.history.push('/dashboard')
    }
  }, [])

  return (
    <div className="auth">
          <a href="https://akuko.io" className="auth-logo">
            Akuko
          </a>
          <form>
            <Input
              size="large"
              placeholder="Enter your email"
              name="username"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <br />
            <Input.Password
              size="large"
              placeholder="Enter your password"
              autoComplete="current-password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading}
              style={{ width: '100%' }}
              onClick={async (e) => {
                e.preventDefault();
                setLoading(true);
                if (email && password) {
                localStorage.setItem('email', email.toLowerCase().trim());
                const service = new AkukoAPIService(IDENTITY_API, 'user/has-password');
                const passwordCheck = await service.read(email.toLowerCase().trim())
                .catch((err) => {
                  setLoading(false);
                  message.error('Username or password incorrect.')
                })
                 if (passwordCheck?.hasPassword === false) {
                   return props.history.push(`/user/set-password${props.location.search}`)
                 } else if (passwordCheck?.hasPassword === true) {

                    const service = new AkukoAPIService(IDENTITY_API, 'user/jwt');
                    let response;

                    try {
                      response = await service.create({
                        email: email.toLowerCase().trim(),
                        password: password,
                      });
                    } catch (e) {
                      setLoading(false);
                      message.error('Username or password incorrect.');
                    }

                    if (response) {
                      const { user } = response;
                      localStorage.setItem('email', email);
                      props.actionUserAuth(user);

                      if (user.handle?.includes('__') || !user.handle) {
                        return props.history.push('/user/set-handle');
                      }

                      mixpanel.identify(email);
                      mixpanel.track('Sign in', {});

                      if (props.location.search) {
                        // handle redirect
                        const redirect = props.location.search.split('?redirect=');
                        if (redirect[1]) {
                          // eslint-disable-next-line
                          if (ENV === 'prod') {
                            return (window.location = redirect[1]);
                          } else {
                            return props.history.push(redirect[1]);
                          }
                        }
                      } else {
                        // eslint-disable-next-line
                        if (ENV === 'dev') {
                          return props.history.push(`/dashboard`);
                        }
                        // eslint-disable-next-line
                        if (ENV === 'stg') {
                          return props.history.push(`/dashboard`);
                        }
                        // eslint-disable-next-line
                        if (ENV === 'prod') {
                          return (window.location = `https://app.akuko.io/dashboard`);
                        }
                      }
                    }
                  }
                } else {
                  setLoading(false);
                  message.error('Please enter your credentials')
                }
                
              }}
            >
              { loading &&
                 <LoadingOutlined />
              }
               Sign in
            </Button>
            <div style={{marginTop: '20px'}}>
            <p><Link to="/user/reset-password">Forgot your password?</Link></p>
            <p><StarOutlined /> <span style={{ color: '#777' }}>New to Akuko?</span> <Link to="/user/register">Create an account &raquo;</Link></p>
            </div>
            </form>
            <Footer />
        
    </div>
  );
};

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withRouter(LoginContainer);
