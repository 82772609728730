import React, { useState } from 'react';
import { AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import SpaceForm from '../../SpaceForm';
import { userIsAdmin } from '../../../../helpers';

const AccountMenu = (props) => {
  const [showModal, setShowModal] = useState(false);
  const params = useParams();
  return (
    <div className="account-settings-menu">
      <div className="menu-name">
        <h2>{params.id}</h2>
      </div>
      <Link to={`/account/${params.id}`}>
        <AppstoreOutlined /> Spaces
      </Link>
      {userIsAdmin(props, params.id) && (
        <a
          href="#add-space"
          onClick={(e) => {
            e.preventDefault();
            setShowModal(true);
          }}
        >
          <AppstoreOutlined /> Add space
        </a>
      )}
      <Link to={`/account/${params.id}/settings`}>
        <SettingOutlined /> Account
      </Link>
      {/*}
      {userIsAdmin(props, params.id) && (
      <a
        disabled
        href="#"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <SettingOutlined /> Settings
      </a>
      )}
      {*/}
      {/*}
      <a
        disabled
        href="#"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <IdcardOutlined /> Billing
      </a>
      {*/}
      <SpaceForm
        {...props}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default AccountMenu;
