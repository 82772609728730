import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tooltip, Button, Drawer, Breadcrumb } from 'antd';
import { withRouter, Link, useParams, Redirect } from 'react-router-dom';
import { userCanAccessSpace, getSpaceUsers } from '../../helpers.js';
import SpaceForm from '../SpaceForm/index.js';
import Loader from '../../../../Post/InlineLoader/Loader.js';
import AccountsMenu from '../AccountsMenu/index.js';
import { HomeOutlined, EditOutlined, SettingOutlined, EyeOutlined } from '@ant-design/icons';
import SpaceUsers from './SpaceUsers.js';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionAccountUsersGet,
} from '../../../actions/index.js';
import '../../style.css';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../configs/env';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionAccountUsersGet,
};

const Spaces = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const params = useParams();

  useEffect(() => {
    setShowLoader(true);
    const service = new AkukoAPIService(IDENTITY_API, `space/account/${params.id}`);
    service
      .list()
      .then((spaces) => {
        props.actionAccountSpacesGet(spaces);
      })
      .finally(() => {
        setShowLoader(false);
      });
  }, [params.id]);

  return (
    <div className="dashboard">
      <div className="spaces">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              {params.id}
            </Breadcrumb.Item>
            <Breadcrumb.Item>spaces</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1>Spaces</h1>
        <Row gutter={20}>
          {showLoader && <Loader />}
          {props.user.spaces &&
            props.account.spaces &&
            props.account.spaces.map(
              (item, index) =>
                userCanAccessSpace(item, props) && (
                  <Col key={index} xs={24} lg={12} xl={8}>
                    <div className="space">
                      <div className="space-image" style={{ background: item.config.color }}></div>
                      <Link className="space-name" to={`/space/${item.id}/posts`}>
                        {item.name}
                      </Link>
                      <SpaceUsers spaceId={item.id} {...props} />
                    </div>
                  </Col>
                )
            )}
        </Row>
        <SpaceForm
          {...props}
          showModal={showModal}
          setShowModal={() => {
            setShowModal(false);
          }}
        />
      </div>
    </div>
  );
};

const SpacesContainer = connect(mapStateToProps, mapDispatchToProps)(Spaces);

export default SpacesContainer;
