import React, { useEffect, useState } from 'react';
import { Input, Form, Button, message, Checkbox, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ENV, IDENTITY_API } from '../../../configs/env';
import { AkukoAPIService } from '../../../services/serviceClass';
import { Dictionary } from '@onaio/utils/dist/types/types';
import Footer from '../../Post/Footer';
import './style.css';
import { UserOutlined } from '@ant-design/icons';

const SetHandle: React.FC = () => {
 const history = useHistory();
 const [handleStatus, setHandleStatus] = useState<"" | "error" | "success" | "warning" | "validating" | undefined>('');
 const user = useSelector((state: Dictionary) => state.user);

 const handleIsValid = (value: string) => {
  const re = /^\w+$/;
    if (!re.test(value)) {
      return false;
    }
  return true;
 }

 return(
  <div className="form-set-password">
   <img className="form-set-password--brand" src="https://assets.akuko.io/akuko-color-logo.png" />
   <h1>Create your handle</h1>
   <p>Please create a handle for your Akuko account.</p>
   <Form
    layout="vertical"
    onFinish={ async (values) => {
      const service = new AkukoAPIService(IDENTITY_API, `user/${user.uuid}`);
        const obj = Object.assign({}, user);
        obj.handle = values.handle;
        const response = await service.update(obj).then(() => {
          const service = new AkukoAPIService(
            IDENTITY_API,
            `user/handle/update/${user.handle}/${values.handle}`
          );
          return service.update({});
        }).then(() => {
          message.success('Handle updated')
          history.push('/dashboard')
        }).catch((err) => {
          message.error('Error')
        })
    }}
    onFieldsChange={(values: Dictionary) => {
      if (values[0].value) {
        setHandleStatus('warning');
      }
      if (values[0].value.length > 5) {
        setHandleStatus('success');
      }
    }}
   >
     <Form.Item
       name="handle"
       hasFeedback
       validateTrigger="onChange"
       rules={[
          {
            required: true,
            message: 'Handle is not available',
            validator: (field, value) => {
              if (value !== user.handle) {
                const service = new AkukoAPIService(IDENTITY_API, 'user/handle');
                return service.read(value).then((data) => {
                  const handle = data as Dictionary;
                  if (handle.uuid) {
                    return Promise.reject();
                  } else {
                    if (value.length > 2 && value.length < 16 && handleIsValid(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  }
                });
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
       <Input size="large" addonBefore={(<UserOutlined />)} prefix="@" placeholder="myGreatHandle" />
     </Form.Item>
     <p>Handles may contain letters, numbers and underscores.</p>
    <Button htmlType="submit" size="large" type="primary">Set handle</Button>
   </Form>
   <Footer />
  </div>
 )
};

export { SetHandle };
